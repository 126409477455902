
































import { Component, Vue,Watch } from 'vue-property-decorator';
import { API } from 'aws-amplify';
import { getStreamManagerServer } from '../graphql/queries';
import { onUpdateStreamManagerServer } from '../graphql/subscriptions';
import { StreamManagerServer } from '../API';
import { promises } from 'node:dns';

@Component({
    components: {
        // @ts-ignore
        AddConnection: require('@/components/AddConnection').default,
        // @ts-ignore
        ServerComponent : require('@/components/Server').default,
    },
})
export default class Servers extends Vue {
    private servers: StreamManagerServer[] = [];
    private server_subscriptions: any;
    private selectedTag: string | undefined = 'all';

    @Watch('selectedTag')
    private tagFilterChange(newValue: string) {
        console.log(`Filter change to |${newValue}|`);
        localStorage.setItem('tag-filter-value', newValue);
    }

    private async mounted() {
        console.log('mounted');
        setTimeout(() => {
            const tagfilter = localStorage.getItem('tag-filter-value');
            if (tagfilter) {
                console.log(`Setting tag to |${tagfilter}| from |${this.selectedTag}|`);
                this.selectedTag = tagfilter;
            }
        }, 1000);
        
        await this.getServers();
        await this.subscribeServerChanges();
    }

    private get Servers(): StreamManagerServer[] {
        if (this.servers) {
            return this.servers.sort((l: StreamManagerServer | undefined, r: StreamManagerServer | undefined) => {
                // @ts-ignore
                if (l.id < r.id) {
                    return -1;
                    // @ts-ignore
                } if (l.id > r.name) {
                    return 1;
                }

                return 0;
            });
        }

        return [];
    }

    private get TagSelect(): any[] {
        const ret: any[] = [];

        ret.push({
                value: 'all',
                text: 'All'
        });

        const filters: any[] = [];

        filters.push({
                value: 'filter:active',
                text: 'Active'
        });

        filters.push({
                value: 'filter:idle',
                text: 'Idle'
        });

        ret.push({
            text: 'Filters',
            options: filters,
        });

        const tags: any[] = [];

        this.Tags.sort((l: string, r:string) => {
            if (l < r) return -1;
            else if (r < l) return 1;

            return 0;
        }).forEach((t)=> {
            tags.push({
                value: t,
                text: t
            });
        });

        ret.push({
            text: 'Tags',
            options: tags,
        });

        console.log('tagFilters', ret);

        return ret;
    }

    private get Tags(): string[] {
        const ret: string[] = [];
        if(this.servers) {
            this.servers.forEach((s) => {
                if (s && s.tags && Array.isArray(s.tags.items)) {
                    s.tags.items.forEach((t) => {
                        if (t) {
                            if (t.name && !ret.includes(t.name)) {
                                ret.push(t.name);
                            }
                        }
                    });
                }
            });
        }

        console.log('tags', ret);
        return ret;
    }

    private async unmounted() {
        console.log('unmounted');
        await this.unSubscribeServerChanges();
    }

    private addConnection() {
        // @ts-ignore
        this.$bvModal.show('addConnectionModal');
    }

    private async subscribeServerChanges() {
        const onUpdateStreamManagerServer = /* GraphQL */ `
            subscription OnUpdateStreamManagerServer {
                onUpdateStreamManagerServer {
                    id
                }
            }
        `;
         // @ts-ignore
         this.server_subscriptions = API.graphql({ query: onUpdateStreamManagerServer }).subscribe({
            next: (eventData: any) => {
                console.log('server', eventData.value.data.onUpdateStreamManagerServer.id);
                 (async () => {
                     const server: StreamManagerServer = await this.getServer(eventData.value.data.onUpdateStreamManagerServer.id);
                     let updateId: number = -1;
                     for (let i: number = 0; i< this.servers.length; i++) {
                         console.log(this.servers[i].id + ' === ' + server.id)
                         if (this.servers[i].id === server.id) {
                             updateId = i;
                         }
                     }

                     if(updateId !== -1) {
                         console.log('Update server, ', updateId);
                         this.servers.splice(updateId, 1);
                         this.servers.push(server);
                     }
                 })();
            }
        });
    }

    private async unSubscribeServerChanges() {
       this.server_subscriptions.unsubscribe();
    }

    private async getServer(id: string): Promise<StreamManagerServer> {
        const servers = await API.graphql({
            query: getStreamManagerServer,
            variables: { id: id }
        });
        // @ts-ignore
        const server: StreamManagerServer = servers.data.getStreamManagerServer;
        console.log(server);

        return server;
    }

    private async getServers() {
        const listStreamManagerServers = /* GraphQL */ `
            query ListStreamManagerServers(
                $filter: ModelStreamManagerServerFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listStreamManagerServers(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
                ) {
                items {
                    id
                    name
                    description
                    lastStatisticsTimestamp
                    lastNotificationsTimestamp
                    lastInterfaceStates
                    claimsCanAccess
                    claimsCanEdit
                    createdAt
                    updatedAt
                    version
                    owner {
                        id
                        name
                        description
                        claimsCanAccess
                        claimsCanEdit
                        createdAt
                        updatedAt
                        version
                    }
                    interfaces {
                        nextToken
                    }
                    settings {
                        nextToken
                    }
                    tags {
                        items {
                            serverId
                            id
                            name
                            claimsCanAccess
                            claimsCanEdit
                            createdAt
                            updatedAt
                            version
                        }
                        nextToken
                    }
                    commands {
                    nextToken
                    }
                }
                nextToken
                }
            }
            `;


        const servers = await API.graphql({
            query: listStreamManagerServers
        });
        // @ts-ignore
        this.servers = servers.data.listStreamManagerServers.items;
        console.log(servers);
    }
}
