











































































































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

import {ICinegyRouteSource} from '../services/models/CinegyRouteSource';


import url from 'url';

interface KeyValue {
    key: string;
    value: string;
}

interface SelectItems {
    value: string|undefined;
    text: string|undefined;
    label: string|undefined;
    options: SelectItems[]|undefined;
}

@Component({
    computed: {
    },
    methods: {
    },
})
export default class UDP extends Vue {
    private lurls: any[] = [];
    private parameters: { [key: string]: string } = {};
    private parameterBTName: string[] = [];
    private Ttl: number = 1;
    private selectedSource: string|null = null;
    private selectedSourceAddressIndex: number = -1;
    @Prop({default: []}) private urls!: string[];
    @Prop({default: ''}) private mode!: string;
    @Prop({default: ''}) private id!: string;
    @Prop({default: ''}) private cinegyRouteSources!: ICinegyRouteSource[];
    @Prop({default: ''}) private inputType!: string;
    @Prop({default: ''}) private outputType!: string;

    @Emit()
    private configurationChanged(newValue: string[]) {
        // dummy
    }

    @Emit()
    private typeChange(newUrl: string) {
        // dummy
    }

    @Watch('urls', { immediate: true })
    private onURLSChange(newValue: string[], oldValue: string[]) {
        if (newValue) {
            this.lurls = [];
            this.parameters = {};
            let i: number = 0;

            newValue.forEach((u) => {
                const lurl: any = url.parse(u, false, false);
                this.lurls.push(lurl);

                if (0 === i) {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1) {
                                this.parameters[values[0].toLowerCase()] = values[1];
                            } else {
                                this.parameters[values[0].toLowerCase()] = 'true';
                            }
                        } else {
                            this.parameters[param.toLowerCase()] = 'true';
                        }
                    });
                }
                {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1 && values[0].toLowerCase() === 'bt_name') {
                                this.parameterBTName[i] = values[1];
                            }
                        }
                    });
                }
                i++;
            });
            const ttl = 'ttl';
            this.Ttl = parseInt(this.parameters[ttl], 10);
        }
    }

    private mounted() {
        // @ts-ignore
        // this.updateCinegyRouteSources();
    }

    private get Id(): string {
        return this.id;
    }

    private get CinegyInputs(): SelectItems[] {
        const ret: SelectItems[] = [];
        let currentGroup: SelectItems = { value: '', text: undefined, label: undefined, options: [] };
        let lastGropup: string = '';

        ret.push({value: undefined, text: 'Please select a source', label: undefined, options: undefined});

        if (this.cinegyRouteSources && Array.isArray(this.cinegyRouteSources)) {
            this.cinegyRouteSources.forEach((s) => {
                if (s.address && (s.address.toLowerCase().indexOf('udp://') !== -1 || s.address.toLowerCase().indexOf('rtp://') !== -1)) {
                    const group: string = s.id.substring(0, s.id.lastIndexOf('/'));
                    const name: string = s.id.substring(s.id.lastIndexOf('/') + 1);

                    if (lastGropup !== group) {
                        if (currentGroup.options!.length > 0) {
                            ret.push(currentGroup);
                        }

                        currentGroup = { value: '', text: undefined, label: group, options: [] };
                        lastGropup = group;
                        // @ts-ignore
                        currentGroup.options.push({
                            value: s.id,
                            text: name,
                            label: undefined,
                            options: [],
                        });
                    } else {
                        if (group === '') {
                            ret.push({  value: s.id, text: name, label: undefined, options: undefined});
                        } else {
                            // @ts-ignore
                            currentGroup.options.push({
                                value: s.id,
                                text: name,
                                label: undefined,
                                options: [],
                            });
                        }
                    }
                }
            });

            if (currentGroup.options!.length > 0) {
                ret.push(currentGroup);
            }
        }

        return ret;
    }

    private remove() {
        this.configurationChanged([]);
    }

    private removeAddress(index: number) {
        Vue.delete(this.lurls, index);
        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private addAddress() {
        Vue.set(this.lurls, this.lurls.length, '');
    }

    private Address(index: number): string {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].hostname;
        }

        return '';
    }
    private Port(index: number): number {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].port;
        }

        return 0;
    }

    private AddressName(index: number): string {
        if (this.parameterBTName[index]) {
            return this.parameterBTName[index];
        }

        return '';
    }

    private setSourceFor(index: number): void {
        this.selectedSourceAddressIndex = index;
        this.$bvModal.show('modal-select-source-' + this.Id + '-' + this.mode);
    }

    private setAddress(): void {
        if (this.cinegyRouteSources && Array.isArray(this.cinegyRouteSources)) {
            this.cinegyRouteSources.forEach((s) => {
                if (s.id === this.selectedSource) {
                    const values = s.address.substring(6).split(':');

                    this.addressChanged(values[0], this.selectedSourceAddressIndex);
                    this.portChanged(values[1], this.selectedSourceAddressIndex);
                }
            });
        }
    }

    private get Redundant(): boolean {
        const keyName = 'stream-switch';
        if (this.parameters[keyName]) {
            return this.parameters[keyName].toLowerCase() === 'true';
        }

        return false;
    }

    private get RTPReorderBuffer(): number {
        const param = 'rtp_reorder_size';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 0;
    }

    private get BISSDecode(): boolean {
        const keyName = 'biss_decode';
        if (this.parameters[keyName]) {
            return this.parameters[keyName].toLowerCase() === 'true';
        }

        return false;
    }

    private get BISSKey(): string {
        const param = 'biss_key';
        if (this.parameters[param]) {
            return this.parameters[param];
        }

        return '';
    }

    private get StreamMode(): number {
        const param = 'stream-mode';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 1;
    }


    private get StreamTimeout(): number {
        const param = 'stream-timeout';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 1000;
    }

    /*private get Ttl(): number {
        const ttl = 'ttl';
        if (this.parameters[ttl]) {
            console.log('this.parameters[ttl]', this.parameters[ttl]);
            return parseInt(this.parameters[ttl], 10);
        }

        return 1;
    }

    private set Ttl(value): void {
        const ttl = 'ttl';
        // @ts-ignore
        Vue.set(this.parameters, ttl, value);
        // @ts-ignore
        Vue.set(this.lurls, 0, url.parse(`udp://${this.Address}:${this.Port}?${this.buildParameters()}`));
    }*/

    private get URLS(): string[] {
        return this.lurls;
    }
    private buildParameters(index: number = 0) {
        if (index > 0) {
            if (this.parameterBTName[index] && this.parameterBTName[index] !== '') {
                return 'bt_name=' + this.parameterBTName[index];
            }
            return '';
        }

        let ret: string = '';
        Object.keys(this.parameters).forEach(
            (key) => (ret += `${key}=${this.parameters[key]}&`),
        );

        return ret.substring(0, ret.length - 1);
    }
    private addressChanged(newValue: string, index: number = 0): void {
        if (newValue.indexOf(':') !== -1 && newValue.indexOf('://') === -1) {
            if (newValue.indexOf('?') !== -1) { // also apply settings, will overwrite current settings
                this.typeChange(newValue);
                return;
            } else { // just change address and port, rest of settings are retained
                const addressAndPort = newValue.split(':');
                this.portChanged(addressAndPort[1], index);
            }
        } else if (newValue.indexOf('://') !== -1 && index === 0) {
            this.typeChange(newValue);
            return;
        }
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                // @ts-ignore
                `udp://${newValue}:${this.Port(index)}?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private portChanged(newValue: string, index: number = 0): void {
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                `udp://${this.Address(index)}:${
                    // @ts-ignore
                    newValue
                }?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private get UDP(): boolean {
        const groupconnect = 'udp';
        if (this.parameters[groupconnect]) {
            return this.parameters[groupconnect] === 'true' || this.parameters[groupconnect] === '1';
        }

        return false;
    }

    private get RTP(): boolean {
        const param = 'rtp';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

    private ttlChanged(newValue: string, index: number = 0): void {
        const ttl = 'ttl';
        // @ts-ignore
        Vue.set(this.parameters, ttl, newValue);
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`udp://${this.Address(index)}:${this.Port(index)}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private parameterChanged(newValue: string, parameter: string, index: number = 0) {
        if (index > 0) {
            Vue.set(this.parameterBTName, index, newValue);
        } else {
            if (parameter === 'bt_name' && newValue === '') {
                Vue.delete(this.parameters, parameter);
            } else {
                Vue.set(this.parameters, parameter, newValue);
            }
        }

        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`udp://${this.Address(index)}:${this.Port(index)}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }
}
