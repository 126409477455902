
































import { API } from 'aws-amplify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StreamManagerServer, StreamManagerSetting } from '../API';
import { listStreamManagerSettings } from '../graphql/queries';
import { ILicences } from '../streammanager/services/models/Licences';

@Component({
})
export default class AddConnection extends Vue {
    @Prop() private servers!: StreamManagerServer[];
    
    private sourceSettings: StreamManagerSetting[] = [];
    private targetSettings: StreamManagerSetting[] = [];

    private page: number = 0;
    private source: string = 'none';
    private target: string = 'none';
    private licenses: ILicences = {
        srt: true,
        rist: true,
        rtmp: true,
        hls: true,
        cinegy_route: true,
        capture_playback: true,
        multi_output: true,
        relay: true,
        tudp: true,
        interfaces: 16,
        trial: false,
        trial_end_date: '',
        zixi: false,
        ndi: false,
        biss: false,
    }

    private get ServersSelect(): any {
        const ret: any[] = [];

        ret.push({ value: 'none', text: 'None' });
        this.Servers.forEach((s) => {
            ret.push({ value: s.id, text: s.name });
        });

        return ret;
    }

    private get Servers(): StreamManagerServer[] {
        if (this.servers) {
            return this.servers.sort((l: StreamManagerServer | undefined, r: StreamManagerServer | undefined) => {
                // @ts-ignore
                if (l.id < r.id) {
                    return -1;
                    // @ts-ignore
                } if (l.id > r.name) {
                    return 1;
                }

                return 0;
            });
        }

        return [];
    }

    private async loadSourceSettings() {
        const server = await API.graphql({
            query: listStreamManagerSettings,
            variables: { filter: { serverId: { eq: this.source } } }
        });
        // @ts-ignore
        this.sourceSettings = server.data.listStreamManagerSettings.items;
    }

    private async loadTargetSettings() {
        const server = await API.graphql({
            query: listStreamManagerSettings,
            variables: { filter: { serverId: { eq: this.target } } }
        });
        // @ts-ignore
        this.targetSettings = server.data.listStreamManagerSettings.items;
    }

}
