/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        servers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      servers {
        items {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const listStreamManagerInterfaces = /* GraphQL */ `
  query ListStreamManagerInterfaces(
    $filter: ModelStreamManagerInterfaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreamManagerInterfaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStreamManagerInterface = /* GraphQL */ `
  query GetStreamManagerInterface($id: ID!) {
    getStreamManagerInterface(id: $id) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
      live {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      statisticSummary {
        id
        interfaceId
        timestamp
        serverId
        in_droped1
        in_retransmitted1
        in_bitrate1
        out_droped1
        out_retransmitted1
        out_bitrate1
        in_droped10
        in_retransmitted10
        in_bitrate10
        out_droped10
        out_retransmitted10
        out_bitrate10
        in_droped30
        in_retransmitted30
        in_bitrate30
        out_droped30
        out_retransmitted30
        out_bitrate30
        in_droped60
        in_retransmitted60
        in_bitrate60
        out_droped60
        out_retransmitted60
        out_bitrate60
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
    }
  }
`;
export const listStreamManagerServers = /* GraphQL */ `
  query ListStreamManagerServers(
    $filter: ModelStreamManagerServerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreamManagerServers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStreamManagerServer = /* GraphQL */ `
  query GetStreamManagerServer($id: ID!) {
    getStreamManagerServer(id: $id) {
      id
      name
      description
      lastStatisticsTimestamp
      lastNotificationsTimestamp
      lastInterfaceStates
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      owner {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        servers {
          nextToken
        }
      }
      interfaces {
        items {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      settings {
        items {
          serverId
          key
          value
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      tags {
        items {
          serverId
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      commands {
        items {
          serverId
          id
          interfaceId
          command
          data
          creator
          state
          message
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const getStreamManagerSetting = /* GraphQL */ `
  query GetStreamManagerSetting($key: ID!, $serverId: ID!) {
    getStreamManagerSetting(key: $key, serverId: $serverId) {
      serverId
      key
      value
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const listStreamManagerSettings = /* GraphQL */ `
  query ListStreamManagerSettings(
    $key: ID
    $serverId: ModelIDKeyConditionInput
    $filter: ModelStreamManagerSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStreamManagerSettings(
      key: $key
      serverId: $serverId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serverId
        key
        value
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      nextToken
    }
  }
`;
export const getStreamManagerTag = /* GraphQL */ `
  query GetStreamManagerTag($id: ID!, $serverId: ID!) {
    getStreamManagerTag(id: $id, serverId: $serverId) {
      serverId
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const listStreamManagerTags = /* GraphQL */ `
  query ListStreamManagerTags(
    $id: ID
    $serverId: ModelIDKeyConditionInput
    $filter: ModelStreamManagerTagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStreamManagerTags(
      id: $id
      serverId: $serverId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serverId
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      nextToken
    }
  }
`;
export const getStreamManagerCommand = /* GraphQL */ `
  query GetStreamManagerCommand($id: ID!, $serverId: ID!) {
    getStreamManagerCommand(id: $id, serverId: $serverId) {
      serverId
      id
      interfaceId
      command
      data
      creator
      state
      message
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const listStreamManagerCommands = /* GraphQL */ `
  query ListStreamManagerCommands(
    $id: ID
    $serverId: ModelIDKeyConditionInput
    $filter: ModelStreamManagerCommandFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStreamManagerCommands(
      id: $id
      serverId: $serverId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serverId
        id
        interfaceId
        command
        data
        creator
        state
        message
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      nextToken
    }
  }
`;
export const listStreamManagerLiveInterfaces = /* GraphQL */ `
  query ListStreamManagerLiveInterfaces(
    $filter: ModelStreamManagerLiveInterfaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreamManagerLiveInterfaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      nextToken
    }
  }
`;
export const getStreamManagerLiveInterface = /* GraphQL */ `
  query GetStreamManagerLiveInterface($id: ID!) {
    getStreamManagerLiveInterface(id: $id) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listRouters = /* GraphQL */ `
  query ListRouters(
    $filter: ModelRouterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRouters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRouter = /* GraphQL */ `
  query GetRouter($id: ID!) {
    getRouter(id: $id) {
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      sources {
        items {
          id
          group
          name
          address
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRouterSources = /* GraphQL */ `
  query ListRouterSources(
    $filter: ModelRouterSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRouterSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        address
        revision
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        router {
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        destinations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRouterSource = /* GraphQL */ `
  query GetRouterSource($id: ID!) {
    getRouterSource(id: $id) {
      id
      group
      name
      address
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getRouterDestination = /* GraphQL */ `
  query GetRouterDestination($id: ID!) {
    getRouterDestination(id: $id) {
      id
      name
      group
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      source {
        id
        group
        name
        address
        revision
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        router {
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        destinations {
          nextToken
        }
      }
    }
  }
`;
export const listRouterDestinations = /* GraphQL */ `
  query ListRouterDestinations(
    $filter: ModelRouterDestinationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRouterDestinations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        group
        revision
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        router {
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        source {
          id
          group
          name
          address
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listStreamManagerStatisticSummarys = /* GraphQL */ `
  query ListStreamManagerStatisticSummarys(
    $filter: ModelStreamManagerStatisticSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreamManagerStatisticSummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interfaceId
        timestamp
        serverId
        in_droped1
        in_retransmitted1
        in_bitrate1
        out_droped1
        out_retransmitted1
        out_bitrate1
        in_droped10
        in_retransmitted10
        in_bitrate10
        out_droped10
        out_retransmitted10
        out_bitrate10
        in_droped30
        in_retransmitted30
        in_bitrate30
        out_droped30
        out_retransmitted30
        out_bitrate30
        in_droped60
        in_retransmitted60
        in_bitrate60
        out_droped60
        out_retransmitted60
        out_bitrate60
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      nextToken
    }
  }
`;
export const getStreamManagerStatisticSummary = /* GraphQL */ `
  query GetStreamManagerStatisticSummary($id: ID!) {
    getStreamManagerStatisticSummary(id: $id) {
      id
      interfaceId
      timestamp
      serverId
      in_droped1
      in_retransmitted1
      in_bitrate1
      out_droped1
      out_retransmitted1
      out_bitrate1
      in_droped10
      in_retransmitted10
      in_bitrate10
      out_droped10
      out_retransmitted10
      out_bitrate10
      in_droped30
      in_retransmitted30
      in_bitrate30
      out_droped30
      out_retransmitted30
      out_bitrate30
      in_droped60
      in_retransmitted60
      in_bitrate60
      out_droped60
      out_retransmitted60
      out_bitrate60
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
