import Vue from 'vue';
import Vuex, {Module} from 'vuex';
import { Auth } from 'aws-amplify';

import AuthModule from './Modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthModule
  }
})
