import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Amplify from 'aws-amplify';
// import aws_exports from './aws-exports';
const aws_exports = require('./aws-exports').default;

Vue.config.productionTip = false


import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

import {BootstrapVue, CardPlugin, InputGroupPlugin, ModalPlugin, TooltipPlugin, PopoverPlugin, DropdownPlugin, ToastPlugin, BadgePlugin, VBTogglePlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(CardPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(PopoverPlugin);
Vue.use(DropdownPlugin);
Vue.use(ToastPlugin);
Vue.use(BadgePlugin);
Vue.use(VBTogglePlugin);


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronRight,
  faChevronDown,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faPlusSquare,
  faMinusSquare,
  faWindowClose,
  faChevronCircleDown,
  faChevronCircleRight,
  faStop,
  faPlay,
  faMinus,
  faPlus,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faCogs,
  faSave,
  faBan,
  faCircle,
  faChartPie,
  faRedo,
  faInfoCircle,
  faNetworkWired,
  faTrashAlt,
  faCloudDownloadAlt,
  faTags,
  faBookmark,
  faEdit,
  faClipboard,
  faLink,
  faServer,
 } from '@fortawesome/free-solid-svg-icons';

 library.add(faChevronRight);
 library.add(faChevronDown);
 library.add(faArrowAltCircleUp);
 library.add(faArrowAltCircleDown);
 library.add(faPlusSquare);
 library.add(faWindowClose);
 library.add(faMinusSquare);
 library.add(faChevronCircleDown);
 library.add(faChevronCircleRight);
 library.add(faStop);
 library.add(faPlay);
 library.add(faMinus);
 library.add(faPlus);
 library.add(faPlusCircle);
 library.add(faCheckCircle);
 library.add(faTimesCircle);
 library.add(faSave);
 library.add(faCogs);
 library.add(faBan);
 library.add(faCircle);
 library.add(faChartPie);
 library.add(faRedo);
 library.add(faInfoCircle);
 library.add(faNetworkWired);
 library.add(faTrashAlt);
 library.add(faCloudDownloadAlt);
 library.add(faTags);
 library.add(faBookmark);
 library.add(faEdit);
 library.add(faClipboard);
 library.add(faLink);
 library.add(faServer);


Vue.component('font-awesome-icon', FontAwesomeIcon);

import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate';
// import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  // @ts-ignore
  extend(rule, rules[rule]);
});

// localize('en', en);

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.component('multiselect', Multiselect);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
