





































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import {ILicences} from '../services/models/Licences';
import {ISetting} from '../services/models/Setting';
import {IMultipleOutputs} from '../services/models/Interface';

import url from 'url';

@Component({
    components: {
            // @ts-ignore
            StatisticsComponent: require('./StatisticsGraph').default,
             // @ts-ignore
            StatisticsDetailComponent: require('./StatisticsDetail').default,
            // @ts-ignore
            UDPComponent : require('./UDP').default,
            // @ts-ignore
            SRTComponent : require('./SRT').default,
            // @ts-ignore
            RTMPComponent : require('./RTMP').default,
            // @ts-ignore
            RISTComponent : require('./RIST').default,
            // @ts-ignore
            CinegyRouteComponent : require('./CinegyRouteInput').default,
            // @ts-ignore
            FileComponent : require('./File').default,
        },
        computed: {
        },
        methods: {
        },
})
export default class Multiple extends Vue {


    private DefaultListenPortKey: string = 'streaming.listner.default.port';
    private ListViewDetailsKey: string = 'ui.interfaces.details.mode';

    @Prop({default: { multi : [] }}) private urls!: IMultipleOutputs;
    @Prop({default: ''}) private mode!: string;
    @Prop({default: ''}) private id!: string;
    @Prop({default: ''}) private name!: string;
    @Prop({default: ''}) private settings!: ISetting[];
    @Prop() private licences!: ILicences;

    @Emit()
    private configurationChanged(newValue: IMultipleOutputs | string[]) {
        // dummy
    }

    private get Settings(): { [key: string]: string } {
        const ret: { [key: string]: string } = {};

        if (this.settings) {
            this.settings.forEach((s) => {
                ret[s.key] = s.value;
            });
        }

        return ret;
    }

    private get DefaultListenPort(): number {
        const s = this.Settings;

        if (s.hasOwnProperty(this.DefaultListenPortKey)) {
            try {
                return parseInt(s[this.DefaultListenPortKey], 10);
            } catch (E) { /* nothing to do */}
        }

        return 0;
    }
    private getType(iurl: string): string {
        if (iurl.substring(0, 3).toLowerCase() === 'udp' || iurl.substring(0, 3).toLowerCase() === 'rtp') {
            return 'udp';
        } else if (iurl.substring(0, 3).toLowerCase() === 'srt') {
            return 'srt';
        } else if (iurl.substring(0, 4).toLowerCase() === 'rtmp') {
            return 'rtmp';
        } else if (iurl.substring(0, 4).toLowerCase() === 'rist') {
            return 'rist';
        } else if (iurl.substring(0, 4).toLowerCase() === 'http' && iurl.indexOf('getvirtual') !== -1) {
            return 'cinegy-route';
        } else if (iurl.substring(0, 4).toLowerCase() === 'file') {
            return 'file';
        }
        return iurl.substring(0, 3);
    }

    private outputType(output: string | string[]): string {
        if (output && Array.isArray(output) && output.length > 0) {
            if (output[0].substring(0, 3).toLowerCase() === 'udp') {
                return 'udp';
            } else if (output[0].substring(0, 3).toLowerCase() === 'srt') {
                return 'srt';
            } else if (output[0].substring(0, 4).toLowerCase() === 'rtmp') {
                return 'rtmp';
            } else if (output[0].substring(0, 4).toLowerCase() === 'rist') {
                return 'rist';
            } else if (output[0].substring(0, 4).toLowerCase() === 'file') {
                return 'file';
            }
        } else if (output && typeof(output) === 'string') {
            return this.getType(output);
        }
        return '';
    }

    private outputTypeChange(newUrl: string, index: number) {
            if (Array.isArray(this.urls.multi) && this.outputType(newUrl) !== this.outputType(this.urls.multi[index])) {
                this.$bvModal.msgBoxConfirm(`This will change output from ${this.outputType(this.urls.multi[index])} to ${this.outputType(newUrl)}`, {
                    title: 'Output type change',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        Vue.set(this.urls.multi, index, []);
                        setTimeout(() => {
                            Vue.set(this.urls.multi, index, [newUrl]);
                        }, 10);
                    }
                })
                .catch((err) => {
                    // An error occurred
                });
            } else {
                Vue.set(this.urls.multi, index, []);
                setTimeout(() => {
                    Vue.set(this.urls.multi, index, [newUrl]);
                }, 10);
            }
        }

    private addSRTOutput(): void {
        const port: number = this.DefaultListenPort; // this.DefaultListenPort + (this.interfaceObject.nr * 2) - 2;

        this.urls.multi.push([`srt://:${port}`]);
    }

    private addUDPOutput(): void {
        this.urls.multi.push(['udp://127.0.0.1:1234']);
    }

    private addRISTOutput(): void {
        const port: number = this.DefaultListenPort; // + (this.interfaceObject.nr * 2) - 2;

        this.urls.multi.push([`rist://:${port}`]);
    }

    private addFileOutput(): void {
        this.urls.multi.push(['file://']);
    }

    private outputChanged(newConfig: string[] | IMultipleOutputs, index: number) {
        if (Array.isArray(newConfig) && newConfig.length === 0) {
            Vue.delete(this.urls.multi, index);
        } else {
            Vue.set(this.urls.multi, index, newConfig);
            this.configurationChanged(this.urls);
        }
    }

    private remove() {
        this.configurationChanged([]);
    }
}

