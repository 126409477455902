






























import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
// @ts-ignore
import APIService from '@/services/api';
import url from 'url';

interface KeyValue {
    key: string;
    value: string;
}

interface SelectItems {
    value: string|undefined;
    text: string|undefined;
    label: string|undefined;
    options: SelectItems[]|undefined;
}

@Component({
    computed: {
    },
    methods: {
    },
})export default class CinegyRoute extends Vue {
    private lurls: any[] = [];
    private parameters: { [key: string]: string } = {};
    private parameterBTName: string[] = [];
    private Ttl: number = 1;
    private SelectedCinegyInput: SelectItems|null = null;
    // private inputs: string[] = [];
    @Prop({default: []}) private urls!: string[];
    @Prop({default: ''}) private mode!: string;
    @Prop({default: ''}) private id!: string;
    @Prop({default: []}) private cinegyRouteDestinations!: string[];

    @Emit()
    private configurationChanged(newValue: string[]) {
        // dummy
    }

   /* @Watch('urls', {immediate: true})
    private UrlsChanged(newValue: string[],  oldValue: string[]) {
        if (newValue && newValue.length > 0) {
            const v: string = newValue[0].replace('&amp;', '&');
            const u: url.UrlWithStringQuery = url.parse(v);
            let name: string = '';

            let params: string[] = [];
            if (u && u.query && u.query.indexOf('&') !== -1) {
                params = u.query.split('&');
            } else if (u && u.query && u.query.indexOf('=') !== -1) {
                params.push(u.query);
            }

            params.forEach((param) => {
                if (param.indexOf('=') !== -1) {
                    const values = param.split('=');
                    if (values && values.length > 1 && values[0] === 'name') {
                        name = values[1];
                    }
                }
            });
            this.SelectedCinegyInput = {
                value: newValue[0],
                text: name,
                label: '',
                options: [],
            };
        }
    }*/

    private async mounted() {
        // this.inputs = await APIService.GetCinegyRouteDestinations();
    }

    private replaceAll(str: string, search: string, replace: string): string {
        return str.split(search).join(replace);
    }

    private get CinegyInputs(): SelectItems[] {
        const ret: SelectItems[] = [];
        let currentGroup: SelectItems = { value: '', text: undefined, label: undefined, options: [] };
        let lastGropup: string = '';

        this.cinegyRouteDestinations.forEach((value) => {
            const v: string = value.replace('&amp;', '&');
            const lurl: any = url.parse(v, false, false);

            let params: string[] = [];
            if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                params = lurl.query.split('&');
            } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                params.push(lurl.query);
            }
            const parameters: { [key: string]: string } = {};

            params.forEach((param) => {
                if (param.indexOf('=') !== -1) {
                    const values = param.split('=');
                    if (values.length > 1) {
                        parameters[values[0].toLowerCase()] = values[1];
                    } else {
                        parameters[values[0].toLowerCase()] = 'true';
                    }
                } else {
                    parameters[param.toLowerCase()] = 'true';
                }
            });

            if (parameters.hasOwnProperty('group')) {
                if (lastGropup !== parameters.group) {
                    if (currentGroup.options!.length > 0) {
                        ret.push(currentGroup);
                    }

                    currentGroup = { value: '', text: undefined, label: this.replaceAll(parameters.group, '%2f', '/'), options: [] };
                    lastGropup = parameters.group;
                }

                currentGroup.options!.push({ value, text: parameters.name, options: undefined, label: parameters.name });
            } else {
                ret.push({ value: v, text: parameters.name, options: undefined, label: undefined });
            }
        });

        if (currentGroup.options!.length > 0) {
            ret.push(currentGroup);
        }

        return ret;
    }

    private get CinegyInput(): string[] {
        return [this.urls[0]];
    }

    @Watch('urls', { immediate: true })
    private onURLSChange(newValue: string[], oldValue: string[]) {
        if (newValue) {
            this.lurls = [];
            this.parameters = {};
            let i: number = 0;
            let name: string = '';

            newValue.forEach((ua) => {
                const u: string = ua.replace('&amp;', '&');
                const lurl: any = url.parse(u, false, false);
                this.lurls.push(lurl);

                if (0 === i) {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1) {
                                this.parameters[values[0].toLowerCase()] = values[1];
                            } else {
                                this.parameters[values[0].toLowerCase()] = 'true';
                            }

                            if (values && values.length > 1 && values[0] === 'name') {
                                name = values[1];
                            }
                        } else {
                            this.parameters[param.toLowerCase()] = 'true';
                        }
                    });

                    if (this.cinegyRouteDestinations.includes(newValue[0])) {
                        this.SelectedCinegyInput = {
                            value: newValue[0],
                            text: name,
                            label: '',
                            options: [],
                        };
                    } else if (this.cinegyRouteDestinations.length > 0) {
                        this.SelectedCinegyInput = {
                            value: this.cinegyRouteDestinations[0],
                            text: 'Please select',
                            label: '',
                            options: [],
                        };
                    }
                }
                {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1 && values[0].toLowerCase() === 'bt_name') {
                                this.parameterBTName[i] = values[1];
                            }
                        }
                    });
                }
                i++;
            });
            const ttl = 'ttl';
            this.Ttl = parseInt(this.parameters[ttl], 10);
        }
    }

    private get URLS(): string[] {
        return this.lurls;
    }

    private inputChanged(newValue: SelectItems) {
        this.parameterChanged(newValue.value + '', 'cinegy-input');
    }

    private buildParameters(index: number = 0) {
        if (index > 0) {
            if (this.parameterBTName[index] && this.parameterBTName[index] !== '') {
                return 'bt_name=' + this.parameterBTName[index];
            }
            return '';
        }

        let ret: string = '';
        Object.keys(this.parameters).forEach(
            (key) => (ret += `${key}=${this.parameters[key]}&`),
        );

        return ret.substring(0, ret.length - 1);
    }

    private parameterChanged(newValue: string, parameter: string, index: number = 0) {
        /*if (index > 0) {
            Vue.set(this.parameterBTName, index, newValue);
        } else {
            if (parameter === 'bt_name' && newValue === '') {
                Vue.delete(this.parameters, parameter);
            } else {
                Vue.set(this.parameters, parameter, newValue);
            }
        }

        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`udp://${this.Address(index)}:${this.Port(index)}?${this.buildParameters(index)}`, false, false),
        );
*/
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(newValue, false, false),
        );
        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private remove() {
        this.configurationChanged([]);
    }
}
