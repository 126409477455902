import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators' 
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import store from '@/store'

@Module({
    store: store,
    namespaced: true
})
export default class AuthModule extends VuexModule {
    user: CognitoUser|undefined = undefined
    email: String|undefined = undefined;
    userId: String|undefined = undefined;
    @Mutation
    setUserMutation(name: CognitoUser) {
        this.user = name;
        if (name) {
            // @ts-ignore
            this.email = name.attributes.email;
            // @ts-ignore
            this.userId = name.attributes.sub;
        }
    }

    @Mutation 
    signOutMutation() {
      this.user=undefined;
    }

    @Action({commit: 'signOutMutation'}) 
    async signOut() {
        await Auth.signOut();
        return;
    }

    @Action({commit: 'setUserMutation'}) 
    async getUser() {
        return await Auth.currentAuthenticatedUser();
    }
}