









































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import url from 'url';

import {IRecording} from '../services/models/Recording';

interface KeyValue {
    key: string;
    value: string;
}

@Component ({
    computed: {
    },
    methods: {
    },
})
export default class File extends Vue {
    private lurls: any[] = [];
    private parameters: { [key: string]: string } = {};
    private parameterBTName: string[] = [];
    private Ttl: number = 1;
    @Prop({default: ''}) private capturePath!: string;
    @Prop({default: []}) private recordings!: IRecording[];
    @Prop({default: []}) private urls!: string[];
    @Prop({default: ''}) private mode!: string;
    @Prop({default: ''}) private id!: string;

    @Emit()
    private configurationChanged(newValue: string[]) {
        // dummy
    }

    private mounted() {
        // @ts-ignore
        // this.updateRecordings();
    }

    @Watch('urls', { immediate: true })
    private onURLSChange(newValue: string[], oldValue: string[]) {
        if (newValue) {
            this.lurls = [];
            this.parameters = {};
            let i: number = 0;

            newValue.forEach((u) => {
                const lurl: any = url.parse(u, false, false);
                this.lurls.push(lurl);

                if (0 === i) {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1) {
                                this.parameters[values[0].toLowerCase()] = values[1];
                            } else {
                                this.parameters[values[0].toLowerCase()] = 'true';
                            }
                        } else {
                            this.parameters[param.toLowerCase()] = 'true';
                        }
                    });
                }
                {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1 && values[0].toLowerCase() === 'bt_name') {
                                this.parameterBTName[i] = values[1];
                            }
                        }
                    });
                }
                i++;
            });
            const ttl = 'ttl';
            this.Ttl = parseInt(this.parameters[ttl], 10);
        }
    }

    private get StartValues(): KeyValue[] {
        const ret: KeyValue[] = [];

        const selectedRecording = this.RecordingName;
        let lastValue: string = '';
        this.recordings!.forEach((r) => {
            if (r.name === selectedRecording) {
                r.files!.forEach((f) => {
                    ret.push({ key : f.replace('.ts', ''), value : lastValue});
                    lastValue = f.replace('.ts', '');
                });
            }
        });

        return ret;
    }

    private remove() {
        this.configurationChanged([]);
    }

    private removeAddress(index: number) {
        Vue.delete(this.lurls, index);
    }

    private addAddress() {
        Vue.set(this.lurls, this.lurls.length, '');
    }

    private get RecordingName() {
         const key = 'recording-name';

         if (this.parameters[key]) {
             return decodeURI(this.parameters[key]);
         }

         return '';
    }

    private get AutoDelete(): boolean {
        const key = 'auto-delete';

        if (this.parameters[key]) {
            return this.parameters[key].toLowerCase() === 'true' ;
        }

        return false;
    }

    private get LoopPlayback(): boolean {
        const key = 'loop';

        if (this.parameters[key]) {
            return this.parameters[key].toLowerCase() === 'true' ;
        }

        return false;
    }

    private get LoopStartFirstFile(): boolean {
        const key = 'loop-startfirst-record';

        if (this.parameters[key]) {
            return this.parameters[key].toLowerCase() === 'true' ;
        }

        return false;
    }

    private get DelayerBuffer(): number {
        const key = 'delayer-buffer';

        if (this.parameters[key]) {
            return parseInt(this.parameters[key], 10);
        }

        return 0; // default value
    }

    private get PlaybackId(): number {
        const key = 'playback-id';

        if (this.parameters[key]) {
            return parseInt(this.parameters[key], 10);
        }

        return 0; // default value
    }

    private get StartAfter(): string {
         const key = 'start-after';

         if (this.parameters[key]) {
             return decodeURI(this.parameters[key]);
         }

         return '';
    }

    private get URLS(): string[] {
        return this.lurls;
    }
    private buildParameters(index: number = 0) {
        if (index > 0) {
            if (this.parameterBTName[index] && this.parameterBTName[index] !== '') {
                return 'bt_name=' + this.parameterBTName[index];
            }
            return '';
        }

        let ret: string = '';
        Object.keys(this.parameters).forEach(
            (key) => (ret += `${key}=${this.parameters[key]}&`),
        );

        return ret.substring(0, ret.length - 1);
    }

    private parameterChanged(newValue: string, parameter: string, index: number = 0) {
        if (index > 0) {
            Vue.set(this.parameterBTName, index, newValue);
        } else {
            if (parameter === 'bt_name' && newValue === '') {
                Vue.delete(this.parameters, parameter);
            } else {
                Vue.set(this.parameters, parameter, newValue);
            }
        }

        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`file://${this.capturePath}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }
}
