



























































































































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import url from 'url';

import {ICinegyRouteSource} from '../services/models/CinegyRouteSource';
import {ISetting} from '../services/models/Setting';

interface ValidationInput {
    dirty: boolean;
    validated: boolean;
    valid: boolean;
}

interface SelectItems {
    value: string|undefined;
    text: string|undefined;
    label: string|undefined;
    options: SelectItems[]|undefined;
}

@Component({
    computed: {
    },
    methods: {
    },
})
export default class SRT extends Vue {
    private lurls: any[] = [];
    private parameters: { [key: string]: string } = {};
    private parametersIndex: { [key: number]: { [key: string]: string } } = {};

    private selectedSource: string|null = null;
    private selectedSourceAddressIndex: number = -1;

    private ExternalIPKey: string = 'server.external_ip';
    private showNetworkBindings: boolean = false;

    private Modes: object[] = [
        {
            value: 'default',
            text: 'Auto',
        },
        {
            value: 'caller',
            text: 'Caller',
        },
        {
            value: 'listener',
            text: 'Listener',
        },
        {
            value: 'rendezvous',
            text: 'Rendezvous',
        },
    ];

    private GroupTypes: object[] = [
        {
            value: 1,
            text: 'BROADCAST (Active/Active)',
        },
        {
            value: 2,
            text: 'BACKUP (Active/Passive)',
        },
        {
            value: 3,
            text: 'BALANCING (Not implemented)',
        },
    ];

    @Prop({ default: [] }) private urls!: string[];
    @Prop({ default: '' }) private mode!: string;
    @Prop({ default: '' }) private id!: string;
    @Prop({ default: '' }) private name!: string;
    @Prop({ default: [] }) private cinegyRouteSources!: ICinegyRouteSource[];
    @Prop({ default: [] }) private settings!: ISetting[];
    @Emit()
    private configurationChanged(newValue: string[]) {
        // dummy
    }

    @Emit()
    private typeChange(newUrl: string) {
        // dummy
    }

    @Watch('urls', { immediate: true })
    private onURLSChange(newValue: string[], oldValue: string[]) {
        if (newValue) {
            this.lurls = [];
            this.parameters = {};
            let i: number = 0;

            newValue.forEach((u) => {
                const lurl: any = url.parse(u, false, false);
                this.lurls.push(lurl);
                this.parametersIndex[i] = {};

                if (0 === i) {
                    let params: string[] = [];
                    if (lurl && (lurl.query && lurl.query.indexOf('&') !== -1) || lurl.hash) {
                        const query = lurl.query + (lurl.hash !== null ? lurl.hash : '');
                        params = query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1) {
                                if (values.length === 2) {
                                    this.parameters[values[0].toLowerCase()] = values[1];
                                    this.parametersIndex[i][values[0].toLowerCase()] = values[1];
                                } else {
                                    this.parameters[values[0].toLowerCase()] = values.join('=').replace(`${values[0]}=`, '');
                                    this.parametersIndex[i][values[0].toLowerCase()] = values.join('=').replace(`${values[0]}=`, '');
                                }
                            } else {
                                this.parameters[values[0].toLowerCase()] = 'true';
                                this.parametersIndex[i][values[0].toLowerCase()] = 'true';
                            }
                        } else {
                            this.parameters[param.toLowerCase()] = 'true';
                            this.parametersIndex[i][param.toLowerCase()] = 'true';
                        }
                    });
                }
                i++;
            });
        }
    }

    private mounted() {
        // @ts-ignore
       // this.updateCinegyRouteSources();
    }

    private get SRTConnectURL(): string {
        if (this.Address(0) === '' || this.Mode === 'listener') {
            return `srt://${this.Settings[this.ExternalIPKey]}:${this.Port(0)}?${this.Passphrase !== '' ? 'passphrase=' + this.Passphrase + '&pbkeylen=' + this.Pbkeylen : ''}`;
        } else if (this.Mode === 'rendezvous') {
             return `srt://${this.Settings[this.ExternalIPKey]}:${this.Port(0)}?mode=rendezvous&${this.Passphrase !== '' ? 'passphrase=' + this.Passphrase + '&pbkeylen=' + this.Pbkeylen : ''}`;
        }

        return `srt://:${this.Port(0)}?${this.Passphrase !== '' ? 'passphrase=' + this.Passphrase + '&pbkeylen=' + this.Pbkeylen : ''}`;
    }

    private get Settings(): { [key: string]: string } {
        const ret: { [key: string]: string } = {};

        if (this.settings) {
            this.settings.forEach((s) => {
                ret[s.key] = s.value;
            });
        }

        return ret;
    }
    private copyURLToClipboard(): void {
        this.$bvToast.toast('Copied to clipborad', {
            title: 'SRT URL',
            variant: 'info',
            solid: true,
        });
    }

    private get RTPReorderBuffer(): number {
        const param = 'rtp_reorder_size';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 0;
    }

    private get CinegyInputs(): SelectItems[] {
        const ret: SelectItems[] = [];
        let currentGroup: SelectItems = { value: '', text: undefined, label: undefined, options: [] };
        let lastGropup: string = '';

        ret.push({value: undefined, text: 'Please select a source', label: undefined, options: undefined});

        if (this.cinegyRouteSources && Array.isArray(this.cinegyRouteSources)) {
            this.cinegyRouteSources.forEach((s) => {
                if (s.address && s.address.toLowerCase().indexOf('srt://') !== -1 ) {
                    const group: string = s.id.substring(0, s.id.lastIndexOf('/'));
                    const name: string = s.id.substring(s.id.lastIndexOf('/') + 1);

                    if (lastGropup !== group) {
                        if (currentGroup.options!.length > 0) {
                            ret.push(currentGroup);
                        }

                        currentGroup = { value: '', text: undefined, label: group, options: [] };
                        lastGropup = group;
                        // @ts-ignore
                        currentGroup.options.push({
                                value: s.id,
                                text: name,
                                label: undefined,
                                options: [],
                            });
                    } else {
                        if (group === '') {
                            ret.push({  value: s.id, text: name, label: undefined, options: undefined});
                        } else {
                            // @ts-ignore
                            currentGroup.options.push({
                                value: s.id,
                                text: name,
                                label: undefined,
                                options: [],
                            });
                        }
                    }
                }
            });

            if (currentGroup.options!.length > 0) {
                ret.push(currentGroup);
            }
        }

        return ret;
    }

    private get NetworkDevices(): any[] {
        return  [
            {
                value: '',
                text: 'auto',
            },
            {
                value: 'lo',
                text: 'localhost',
            },
            {
                value: 'eth0',
                text: 'eth0',
            },
            {
                value: 'eth1',
                text: 'eth1',
            },
        ];
    }

    private Bindtodevice(index: number): string {
        const param = 'bindtodevice';
        if (this.parametersIndex[index] && this.parametersIndex[index][param]) {
            return this.parametersIndex[index][param];
        }

        return '';
    }

    private get ShowBindtodevice(): boolean {
        const param = 'bindtodevice';
        if (this.showNetworkBindings) {
            return true;
        }

        for (let i: number = 0; i < this.lurls.length; i++) {
            if (this.parametersIndex[i] && this.parametersIndex[i][param]) {
                return true;
            }
        }
        return false;
    }

    private remove() {
        this.configurationChanged([]);
    }

    private getValidationState(/*{ dirty, validated, valid = null }*/input: ValidationInput): any {
      return input.dirty || input.validated ? input.valid : null;
    }

    private parameterChanged(newValue: string, parameter: string, index: number = 0) {
        if (index === 0) {
            Vue.set(this.parameters, parameter, newValue);
        }
        Vue.set(this.parametersIndex[index], parameter, newValue);
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`srt://${this.Address(index)}:${this.Port(index)}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private removeAddress(index: number) {
        Vue.delete(this.lurls, index);

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private addAddress() {
        Vue.set(this.lurls, this.lurls.length, '');
    }

    private Address(index: number): string {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].hostname;
        }

        return '';
    }

    private Port(index: number): number {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].port;
        }

        return 0;
    }

    private get Mode(): string {
        const param = 'mode';
        if (this.parameters[param]) {
            return this.parameters[param];
        }

        return 'default';
    }

    private get GroupType(): number {
        const param = 'type';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 1;
    }

    private get Latency(): number {
        const param = 'latency';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 120;
    }

    private get Id(): string {
        return this.id;
    }

    private get Passphrase(): string {
        const param = 'passphrase';
        if (this.parameters[param]) {
            return this.parameters[param];
        }

        return '';
    }

    private get Pbkeylen(): number {
        const param = 'pbkeylen';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 16;
    }

    private get Tlpktdrop(): boolean {
        const param = 'tlpktdrop';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true';
        }

        return true;
    }

    private get StreamId(): string {
        const param = 'streamid';
        if (this.parameters[param]) {
            return this.parameters[param];
        }

        return '';
    }

    private get Conntimeo(): number {
        const param = 'conntimeo';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 3000;
    }

    private get Groupconnect(): boolean {
        const param = 'groupconnect';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

     private get UDP(): boolean {
        const param = 'udp';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

    private get RTP(): boolean {
        const param = 'rtp';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

    private buildParameters(index: number = 0) {
        if (index > 0) {
         /*   if (this.parameterBTName[index] && this.parameterBTName[index] !== '') {
                return 'bt_name=' + this.parameterBTName[index];
            }*/
            // return '';
            if (this.parametersIndex[index]) {
                let r: string = '';
                Object.keys(this.parametersIndex[index]).forEach(
                    (key) => {
                        if (key === 'groupconnect') {
                            if (this.parametersIndex[index][key]) {
                                r += `${key}=1&`;
                            }
                        } else {
                            r += `${key}=${this.parametersIndex[index][key]}&`;
                        }
                    },
                );
                return r.substring(0, r.length - 1);
            }
            return '';
        }

        let ret: string = '';
        Object.keys(this.parameters).forEach(
            (key) => {
                if (key === 'groupconnect') {
                    if (this.parameters[key]) {
                        ret += `${key}=1&`;
                    }
                } else {
                    ret += `${key}=${this.parameters[key]}&`;
                }
            },
        );

        return ret.substring(0, ret.length - 1);
    }

    private addressChanged(newValue: string, index: number = 0): void {
        if (newValue.indexOf(':') !== -1 && newValue.indexOf('://') === -1) {
            if (newValue.indexOf('?') !== -1) { // also apply settings, will overwrite current settings
                this.typeChange(newValue);
                return;
            } else { // just change address and port, rest of settings are retained
                const addressAndPort = newValue.split(':');
                this.portChanged(addressAndPort[1], index);
                newValue = addressAndPort[0];
            }
        } else if (newValue.indexOf('://') !== -1 && index === 0) {
            this.typeChange(newValue);
            return;
        }
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                // @ts-ignore
                `srt://${newValue}:${this.Port(index)}?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );
        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private portChanged(newValue: string, index: number = 0): void {
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                `srt://${this.Address(index)}:${
                    // @ts-ignore
                    newValue
                }?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private setSourceFor(index: number): void {
        this.selectedSourceAddressIndex = index;
        this.$bvModal.show('modal-select-source-' + this.Id + '-' + this.mode);
    }

    private setAddress(): void {
        if (this.cinegyRouteSources && Array.isArray(this.cinegyRouteSources)) {
            this.cinegyRouteSources.forEach((s) => {
                if (s.id === this.selectedSource) {
                    const values = s.address.substring(6).split(':');

                    this.addressChanged(values[0], this.selectedSourceAddressIndex);
                    this.portChanged(values[1], this.selectedSourceAddressIndex);
                }
            });
        }
    }
}
