

















































































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import url from 'url';

interface ValidationInput {
    dirty: boolean;
    validated: boolean;
    valid: boolean;
}

@Component
export default class RIST extends Vue {
    private lurls: any[] = [];
    private parameters: { [key: string]: string } = {};
    @Prop({ default: [] }) private urls!: string[];
    @Prop({ default: '' }) private mode!: string;
    @Prop({ default: '' }) private id!: string;
    @Prop({ default: '' }) private name!: string;
    @Emit()
    private configurationChanged(newValue: string[]) {
        // dummy
    }

    @Emit()
    private typeChange(newUrl: string) {
        // dummy
    }

    @Watch('urls', { immediate: true })
    private onURLSChange(newValue: string[], oldValue: string[]) {
        if (newValue) {
            this.lurls = [];
            this.parameters = {};
            let i: number = 0;

            newValue.forEach((u) => {
                const lurl: any = url.parse(u, false, false);
                this.lurls.push(lurl);

                if (0 === i) {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1) {
                                this.parameters[values[0].toLowerCase()] = values[1];
                            } else {
                                this.parameters[values[0].toLowerCase()] = 'true';
                            }
                        } else {
                            this.parameters[param.toLowerCase()] = 'true';
                        }
                    });
                }
                i++;
            });
        }
    }
    private remove() {
        this.configurationChanged([]);
    }

    private getValidationState(/*{ dirty, validated, valid = null }*/input: ValidationInput): any {
      return input.dirty || input.validated ? input.valid : null;
    }

    private parameterChanged(newValue: string, parameter: string, index: number = 0) {
        Vue.set(this.parameters, parameter, newValue);
        // @ts-ignore
        Vue.set(
            this.lurls,
            0,
            url.parse(`rist://${this.Address(index)}:${this.Port(index)}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private removeAddress(index: number) {
        Vue.delete(this.lurls, index);
    }

    private addAddress() {
        Vue.set(this.lurls, this.lurls.length, '');
    }

    private Address(index: number): string {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].hostname;
        }

        return '';
    }

    private Port(index: number): number {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].port;
        }

        return 0;
    }
    private get Buffer(): number {
        const param = 'buffer';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 120;
    }

    private get Secret(): string {
        const param = 'secret';
        if (this.parameters[param]) {
            return this.parameters[param];
        }

        return '';
    }

    private get Profile(): number {
        const param = 'profile';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 0;
    }

    private get EncryptionType(): number {
        const param = 'encryption-type';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 0;
    }

    private get Tlpktdrop(): boolean {
        const param = 'tlpktdrop';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true';
        }

        return true;
    }

    private get StreamId(): string {
        const param = 'streamid';
        if (this.parameters[param]) {
            return this.parameters[param];
        }

        return '';
    }

    private get Conntimeo(): number {
        const param = 'conntimeo';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 3000;
    }

    private get Groupconnect(): boolean {
        const param = 'groupconnect';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

     private get UDP(): boolean {
        const param = 'udp';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

    private get RTP(): boolean {
        const param = 'rtp';
        if (this.parameters[param]) {
            return this.parameters[param] === 'true' || this.parameters[param] === '1';
        }

        return false;
    }

    private buildParameters(index: number = 0) {
        if (index > 0) {
         /*   if (this.parameterBTName[index] && this.parameterBTName[index] !== '') {
                return 'bt_name=' + this.parameterBTName[index];
            }*/
            return '';
        }

        let ret: string = '';
        Object.keys(this.parameters).forEach(
            (key) => {
                if (key === 'groupconnect') {
                    if (this.parameters[key]) {
                        ret += `${key}=1&`;
                    }
                } else {
                    ret += `${key}=${this.parameters[key]}&`;
                }
            },
        );

        return ret.substring(0, ret.length - 1);
    }

    private addressChanged(newValue: string, index: number = 0): void {
        if (newValue.indexOf(':') !== -1 && newValue.indexOf('://') === -1) {
            if (newValue.indexOf('?') !== -1) { // also apply settings, will overwrite current settings
                this.typeChange(newValue);
                return;
            } else { // just change address and port, rest of settings are retained
                const addressAndPort = newValue.split(':');
                this.portChanged(addressAndPort[1], index);
                newValue = addressAndPort[0];
            }
        } else if (newValue.indexOf('://') !== -1 && index === 0) {
            this.typeChange(newValue);
            return;
        }
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                // @ts-ignore
                `rist://${newValue}:${this.Port(index)}?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );
        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private portChanged(newValue: string, index: number = 0): void {
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                `rist://${this.Address(index)}:${
                    // @ts-ignore
                    newValue
                }?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }
}
