






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { API } from 'aws-amplify';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { getStreamManagerServer, listStreamManagerSettings, getStreamManagerInterface, listStreamManagerTags, getStreamManagerLiveInterface, listStreamManagerStatisticSummarys } from '../graphql/queries';
import { createStreamManagerCommand } from '../graphql/mutations';
import { onDeleteStreamManagerServer } from '../graphql/subscriptions';

import { StreamManagerServer, StreamManagerInterface, StreamManagerSetting } from '../API';
import {ILicences} from '../streammanager/services/models/Licences';
import {IInterface, ITag} from '../streammanager/services/models/Interface';
import { IStatisticsSummary, IStatisticsSummaryDetail } from '../streammanager/services/models/StatisticsSummaries';
import { ISetting } from '../streammanager/services/models/Setting';
import { IStatus, IStatusWrapper } from '../streammanager/services/models/Status';

import { stat } from 'fs/promises';
import moment from 'moment';


@Component({
    computed: {
        ...mapState('AuthModule',['userId']),
    },
    components: {
        // @ts-ignore
        InterfaceComponent : require('@/streammanager/components/Interface').default,
    },
})
export default class Servers extends Vue {
    @Prop()server!: StreamManagerServer;
    @Prop()tagFilter!: string; 
    private userId!: string;

    private serverStateUpdateInterval: any = undefined;
    private serverStateVersion: number = 0;
    private interfaces: StreamManagerInterface[] = [];
    private iInterfaces: IInterface[] = [];
    private settings: StreamManagerSetting[] = [];
    private collapsed: boolean = true;
    private loadingStatistics: boolean = false;
    private interface_subscription: any;
    private shiftDown: boolean = false;
    private recordings: [] = [];
    private cinegyRouteSources: [] = [];
    private tags: ITag[] = [];
    private licenses: ILicences = {
        srt: true,
        rist: true,
        rtmp: true,
        hls: true,
        cinegy_route: true,
        capture_playback: true,
        multi_output: true,
        relay: true,
        tudp: true,
        interfaces: 16,
        trial: false,
        trial_end_date: '',
        zixi: false,
        ndi: false,
        biss: false,
    }
    private  dateO = (new Date((Date.now()  - (10 * 60 * 1000))));
        
      
    private lastStatisticsTimestamps: string = `${this.dateO.getUTCFullYear()}-${(this.dateO.getUTCMonth() + 1).toString().padStart(2, '0')}-${this.dateO.getUTCDate().toString().padStart(2, '0')}T${this.dateO.getUTCHours().toString().padStart(2, '0')}:${this.dateO.getUTCMinutes().toString().padStart(2, '0')}:${this.dateO.getUTCSeconds().toString().padStart(2, '0')}Z`;
    private statisticsSummary: { [key: string]: IStatisticsSummary } = {};
    private statisticsSummaryUpdated: number = 0;

    @Watch('server', { immediate: true })
    private onURLSChange(newValue: StreamManagerServer, oldValue: StreamManagerServer) {
        console.log('Server Change');
    }

    private get Id(): string {
        if (this.server && this.server.id) {
            return this.server.id;
        }

        return '';
    }

    private get Name(): string {
       if (this.server && this.server.name) {
            return this.server.name;
        }

        return '';
    }

    private isInterfaceVisible(inter: IInterface) {
        console.log('inter', inter);
        console.log('this.tagFilter', this.tagFilter);

            if (!this.tagFilter || this.tagFilter === 'all') {
                return true;
            }

            if (this.tagFilter.indexOf('filter:') !== -1) {
                switch (this.tagFilter.toLowerCase()) {
                    case 'filter:active': return inter.live && inter.live.state === 1;
                    case 'filter:idle': return (inter.live && inter.live.state === 0) || inter.live === undefined;
                    case 'filter:configured': return (inter.input.length !== 0 || ( Array.isArray(inter.output) && inter.output.length > 0) || !Array.isArray(inter.output) && inter.output.multi);
                    case 'filter:free': return (inter.input.length === 0 && ( Array.isArray(inter.output) && inter.output.length === 0));
                }
            } else {
                if (inter && inter.tags && inter.tags.length > 0) {
                    // tslint:disable-next-line
                    for (let i: number = 0; i < inter.tags.length; i++) {
                        const tag: ITag = inter.tags[i];

                        if (tag.name === this.tagFilter) {
                            return true;
                        }
                    }
                }
            }

            return false;
        }

    private get StateClass(): string {
        const ver: number = this.serverStateVersion;
        const startedAt: string | undefined = this.server.updatedAt;
        if (startedAt) {
            const duration = moment.duration(moment(new Date()).diff(moment(startedAt)));
            if (duration.asMinutes() > 25) {
                return 'red';
            } else if (duration.asMinutes() > 5) {
                return 'yellow';
            }
            return 'green';
        }

        return 'red';
    }

    private zeroPad(nr: number, places: number): string {
        return String(nr).padStart(places, '0');
    }

    private get TimeSinceUpdate(): string {
        const ver: number = this.serverStateVersion;
        const startedAt: string | undefined = this.server.updatedAt;
        if (startedAt) {
            return moment(startedAt).fromNow();
        }

        return '';
      /*  const duration = moment.duration(moment(new Date()).diff(moment(startedAt)));

        // Get Days
        const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
        const daysFormatted = days ? `${days}d ` : ''; // if no full days then do not display it at all

        // Get Hours
        const hours = this.zeroPad(duration.hours(), 2);
        const hoursFormatted = `${hours}:`;

        // Get Minutes
        const minutes = this.zeroPad(duration.minutes(), 2);
        const minutesFormatted = `${minutes}:`;

        // Get Minutes
        const seconds = this.zeroPad(duration.seconds(), 2);
        const secondsFormatted = `${seconds}`;

        return [daysFormatted, hoursFormatted, minutesFormatted, secondsFormatted].join(''); */
    }

    private getInterfaceState(id: string): IStatus[] {
        const ret: IStatus[] = [];
        // @ts-ignore
        const states: any = JSON.parse(this.server.lastInterfaceStates);
        for(const stat in states) {
            const state: any = states[stat];
            console.log(state);
            
            
            let inStats: IStatusWrapper[] | null = [{
                socket: -1,
                name: '',
                data: [],
            }];
            let outStats: IStatusWrapper[] | null = [{
                socket: -1,
                name: '',
                data: [],
            }]; 
            

            if (state.inCount === 0) {
                inStats = null;
            }

            if(state.outCount === 0) {
                outStats = null;
            }

            for(let i = 0; i < state.inCount; i++) {
                if (inStats && inStats.length > 0) {
                    inStats[0].data.push({
                        client: '',
                        sid: -1,
                        time: -1,
                        window: {
                            flow: -1,
                            congestion: -1,
                            flight: -1,
                        },
                        link: {
                            rtt: -1,
                            bandwidth: -1,
                            maxBandwidth: -1,
                        },
                        send: {
                            packets: 0,
                            packetsLost: 0,
                            packetsDropped: 0,
                            packetsRetransmitted: 0,
                            bytes: 0,
                            bytesDropped: 0,
                            mbitRate: 0,
                        },
                        recv: {
                            packets: 0,
                            packetsLost: 0,
                            packetsDropped: 0,
                            packetsRetransmitted: 0,
                            bytes: 0,
                            bytesDropped: 0,
                            mbitRate: i === 0?state.inBitrate:0,
                            packetsBelated: 0,
                            bytesLost: 0,
                        },
                        latency: -1,
                        version: -1,
                    });
                }
            }

            for(let i = 0; i < state.outCount; i++) {
                if (outStats && outStats.length > 0) {
                    outStats[0].data.push({
                        client: '',
                        sid: -1,
                        time: -1,
                        window: {
                            flow: -1,
                            congestion: -1,
                            flight: -1,
                        },
                        link: {
                            rtt: -1,
                            bandwidth: -1,
                            maxBandwidth: -1,
                        },
                        send: {
                            packets: 0,
                            packetsLost: 0,
                            packetsDropped: 0,
                            packetsRetransmitted: 0,
                            bytes: 0,
                            bytesDropped: 0,
                            mbitRate: i === 0?state.outBitrate:0,
                        },
                        recv: {
                            packets: 0,
                            packetsLost: 0,
                            packetsDropped: 0,
                            packetsRetransmitted: 0,
                            bytes: 0,
                            bytesDropped: 0,
                            mbitRate: 0,
                            packetsBelated: 0,
                            bytesLost: 0,
                        },
                        latency: -1,
                        version: -1,
                    });
                }
            }

           if(state.interfaceId === id) {
                ret.push({
                    id: id,
                    name: '',
                    input: [],
                    output: [],
                    type: -1,
                    state: -1,
                    auto_start: false,
                    timestamp: (new Date()).toString(),
                    started_at: '',
                    input_statistics: inStats,
                    output_statistics: outStats,
                })
            }
        }

        console.log('ret', ret);

        return ret;
    }

    private get Interfaces(): IInterface[] {
        const serverVersion: number | undefined = this.server.version;
        console.log('version', serverVersion);
        
        return this.iInterfaces.sort((l: IInterface, r: IInterface) => {
            l.live.statistics = this.getInterfaceState(l.id);
            if(l.live.statistics.length > 0) {
                console.log('live', l.live);
            }
            // @ts-ignore
            if (l.nr < r.nr) {
                return -1;
            } 
            // @ts-ignore
            else if ( r.nr < l.nr) {
                return 1;
            }
            return 0;
        });
    }

    private get Settings(): ISetting[] {
        const ret: ISetting[] = [];

        if (this.settings && this.settings.length > 0) {
            this.settings.forEach((s) => {
                if (s.key === 'ui.interfaces.details.mode') {
                    ret.push({
                        key: 'ui.interfaces.details.mode',
                        value: 'list',
                    });
                } else {
                    ret.push({
                        // @ts-ignore
                        key: s.key,
                        // @ts-ignore
                        value: s.value,
                    });
                }
            });
        }

        return ret;
    }

    private get StatisticsSummary(): { [key: string]: IStatisticsSummary } {
        if (this.statisticsSummaryUpdated > 0) {
            return this.statisticsSummary;
        }

        return {};
    }
    private async unmounted() {
        clearInterval(this.serverStateUpdateInterval);
    }

    private async mounted() {
        
      /*  const dateO = (new Date((Date.now()  - (10 * 60 * 1000))));
         this.lastStatisticsTimestamps = `${dateO.getUTCFullYear()}-${(dateO.getUTCMonth() + 1).toString().padStart(2, '0')}-${dateO.getUTCDate().toString().padStart(2, '0')}T${dateO.getUTCHours().toString().padStart(2, '0')}:${dateO.getUTCMinutes().toString().padStart(2, '0')}:${dateO.getUTCSeconds().toString().padStart(2, '0')}Z`;
        console.log("this.lastStatisticsTimestamps", this.lastStatisticsTimestamps);
        console.log((dateO.getUTCDate()).toString().padStart(2, '0'));*/
        /*setInterval(async () => {
            if (!this.collapsed && !this.loadingStatistics) {
                this.loadingStatistics = true;
                console.log("Load new statistics");
                this.loadStatistics().then(() => {
                    this.loadingStatistics = false;
                    console.log("Load new statistics DONE");
                });
            }
        }, 5000);*/

        this.serverStateUpdateInterval = setInterval(() => {
            this.serverStateVersion += 1;
        }, 5000);
        this.GetStreamManagerSummaries();
        setInterval(async () => {
            console.log('update Summaries');
            this.GetStreamManagerSummaries();
        }, 55000);
    }

    private async Start(i: IInterface) {
        const server = await API.graphql({
            query: createStreamManagerCommand,
            variables: { 
                input: {
                    claimsCanAccess: this.server.claimsCanAccess, 
                    claimsCanEdit: this.server.claimsCanEdit, 
                    command: 'START', 
                    creator: this.userId, 
                    data: '', 
                    state: 'ISSUED',
                    interfaceId: i.id, 
                    serverId: this.server.id, 
                    streamManagerCommandServerId: this.server.id
                }
            }
        });
    }

    private async Restart(i: IInterface) {
        const server = await API.graphql({
            query: createStreamManagerCommand,
            variables: { 
                input: {
                    claimsCanAccess: this.server.claimsCanAccess, 
                    claimsCanEdit: this.server.claimsCanEdit, 
                    command: 'RESTART', 
                    creator: this.userId, 
                    data: '', 
                    state: 'ISSUED',
                    interfaceId: i.id, 
                    serverId: this.server.id, 
                    streamManagerCommandServerId: this.server.id
                }
            }
        });
    }

    private async Stop(i: IInterface) {
        const server = await API.graphql({
            query: createStreamManagerCommand,
            variables: { 
                input: {
                    claimsCanAccess: this.server.claimsCanAccess, 
                    claimsCanEdit: this.server.claimsCanEdit, 
                    command: 'STOP', 
                    creator: this.userId, 
                    data: '', 
                    state: 'ISSUED',
                    interfaceId: i.id, 
                    serverId: this.server.id, 
                    streamManagerCommandServerId: this.server.id
                }
            }
        });
    }

    private async Save(i: IInterface) {
        const server = await API.graphql({
            query: createStreamManagerCommand,
            variables: { 
                input: {
                    claimsCanAccess: this.server.claimsCanAccess, 
                    claimsCanEdit: this.server.claimsCanEdit, 
                    command: 'SAVE', 
                    creator: this.userId, 
                    data: JSON.stringify(i), 
                    state: 'ISSUED',
                    interfaceId: i.id, 
                    serverId: this.server.id, 
                    streamManagerCommandServerId: this.server.id
                }
            }
        });
    }

    private changeInterfaceValue() {

    }

    private async loadInterfaces() {


 const lgetStreamManagerServer = /* GraphQL */ `
    query GetStreamManagerServer($id: ID!) {
        getStreamManagerServer(id: $id) {
            interfaces(limit: 256, sortDirection: DESC) {
                items {
                    id
                    name
                    description
                    updated
                    started
                    srt_connection_limit
                    auto_start
                    tags
                    nr
                    state
                    input
                    output
                    active_input
                    avtive_output
                    claimsCanAccess
                    claimsCanEdit
                    createdAt
                    updatedAt
                    version
                }
                nextToken
            }
            lastNotificationsTimestamp
            lastStatisticsTimestamp
            name
            owner {
                claimsCanAccess
                claimsCanEdit
                createdAt
                description
                id
                name
                updatedAt
                version
            }
            settings {
                items {
                    claimsCanAccess
                    claimsCanEdit
                    createdAt
                    key
                    serverId
                    updatedAt
                    value
                    version
                }
            }
            tags {
                items {
                    serverId
                    id
                    name
                    claimsCanAccess
                    claimsCanEdit
                    createdAt
                    updatedAt
                    version
                }
                nextToken
            }
            claimsCanAccess
            claimsCanEdit
            createdAt
            description
            id
            updatedAt
            version
        }
    }`;

        const server = await API.graphql({
            query: lgetStreamManagerServer,
            variables: { id: this.Id }
        });
        // @ts-ignore
        this.interfaces = server.data.getStreamManagerServer.interfaces.items;
        // @ts-ignore
        this.settings = server.data.getStreamManagerServer.settings.items;
        // @ts-ignore
        this.tags = server.data.getStreamManagerServer.tags.items;

        this.iInterfaces = [];
        this.interfaces.forEach(async (i) => {

             if (i.state === 1) {
                const li = await API.graphql({
                    query: getStreamManagerLiveInterface,
                    variables: { id: i.id }
                });
                // @ts-ignore
                if (li.data && li.data.getStreamManagerLiveInterface) {
                    // @ts-ignore
                    i.live = li.data.getStreamManagerLiveInterface;
                }
            }

            let interf: IInterface = {
                // @ts-ignore
                id: i.id,
                // @ts-ignore
                nr: i.nr,
                // @ts-ignore
                name: i.name,
                type: 0,
                // @ts-ignore
                state: i.state,
                // @ts-ignore
                auto_start: i.auto_start,
                // @ts-ignore
                srt_connection_limit: i.srt_connection_limit,
                // @ts-ignore
                allow_block_list_id: null,
                // @ts-ignore
                input: JSON.parse(i.input),
                // @ts-ignore
                output: JSON.parse(i.output),
                // @ts-ignore
                live: {
                            // @ts-ignore
                            state: i.state ,
                            started_at: i.live ? i.live.started + '' : '0000-00-00T00:00:00',
                            // @ts-ignore
                            input: JSON.parse(i.input),
                            // @ts-ignore
                            output: JSON.parse(i.output),
                            name: i.name + '',
                            statistics: [],
                        },
                // @ts-ignore
                lastTimestamp: '',
                // @ts-ignore
                tags: JSON.parse(i.tags),
            }
            this.iInterfaces.push(interf);
        });
    }

    private async loadSettings() {
        const server = await API.graphql({
            query: listStreamManagerSettings,
            variables: { filter: { serverId: { eq: this.Id } } }
        });
        // @ts-ignore
        this.settings = server.data.listStreamManagerSettings.items;
    }

     private async loadTags() {
        const server = await API.graphql({
            query: listStreamManagerTags,
            variables: { filter: { serverId: { eq: this.Id } } }
        });
        // @ts-ignore
        this.tags = server.data.listStreamManagerTags.items;
    }

    private async subscribeServers() {
        const onUpdateStreamManagerInterface = /* GraphQL */ `
            subscription OnUpdateStreamManagerInterface {
                onUpdateStreamManagerInterface {
                    id
                }
            }
        `;
        const self = this;
        // @ts-ignore
        this.interface_subscription = API.graphql({ query: onUpdateStreamManagerInterface }).subscribe({
            next: (eventData: any) => {
                let interf = eventData.value.data.onUpdateStreamManagerInterface;
                (async () => {
                    const i = await API.graphql({
                        query: getStreamManagerInterface,
                        variables: { id: interf.id }
                    });

                    // @ts-ignore
                    const ii = i.data.getStreamManagerInterface;
                    if (self.interfaces.some(item => item.id === ii.id)) {
                        const index = self.interfaces.findIndex(item => item.id === ii.id);
                        self.interfaces.splice(index, 1);

                        const iindex = self.iInterfaces.findIndex(item => item.id === ii.id);
                        self.iInterfaces.splice(iindex, 1);

                    }

                    if (ii.state === 1) {
                        const li = await API.graphql({
                            query: getStreamManagerLiveInterface,
                            variables: { id: interf.id }
                        });
                        // @ts-ignore
                        if (li.data && li.data.getStreamManagerLiveInterface) {
                            // @ts-ignore
                            ii.live = li.data.getStreamManagerLiveInterface;
                        }
                    }

                     let iii: IInterface = {
                        id: ii.id,
                        nr: ii.nr,
                        name: ii.name,
                        type: 0,
                        state: ii.state,
                        auto_start: ii.auto_start,
                        srt_connection_limit: ii.srt_connection_limit,
                        allow_block_list_id: ii.allow_block_list_id,
                        // @ts-ignore
                        input: JSON.parse(ii.input),
                        // @ts-ignore
                        output: JSON.parse(ii.output),
                        // @ts-ignore
                        live: {
                            state: ii.state,
                            started_at: ii.live ? ii.live.started: '0000-00-00T00:00:00Z',
                            // @ts-ignore
                            input: JSON.parse(ii.input),
                            // @ts-ignore
                            output: JSON.parse(ii.output),
                            name: ii.name,
                            statistics: [],
                        },
                        lastTimestamp: '',
                        // @ts-ignore
                        tags: JSON.parse(ii.tags),
                    }
            
                    self.interfaces = [...self.interfaces, ii];
                    self.iInterfaces = [...self.iInterfaces, iii];
                })();
                /*if (interf.server && interf.server.id === this.Id) {

                    if (this.interfaces.some(item => item.id === interf.id)) {
                        const index = this.interfaces.findIndex(item => item.id === interf.id);
                        this.interfaces.splice(index, 1);
                    }
            
                    this.interfaces = [...this.interfaces, interf];
                }*/
            }
        });
    }

    private async GetStreamManagerSummaries() {
        if (this.StateClass !== 'green' || this.collapsed) {
            console.log('ignoring ' + this.Name);
            return;
        }
        let  summaries: any = { data: { listStreamManagerStatisticSummarys: { nextToken: null} } };
        
        do {
            summaries = await API.graphql({
                query: listStreamManagerStatisticSummarys,
                variables: { nextToken: summaries.data.listStreamManagerStatisticSummarys.nextToken, filter: { serverId: { eq: this.Id } } }
            });

            // @ts-ignore
            if (summaries.data && summaries.data.listStreamManagerStatisticSummarys && summaries.data.listStreamManagerStatisticSummarys.items) {
                // @ts-ignore
                summaries.data.listStreamManagerStatisticSummarys.items.forEach((i) => {
                    this.statisticsSummary[i.interfaceId] = {
                        long_interval: [
                            {
                                interface_id: i.interfaceId,
                                in_retransmitted: i.in_retransmitted60,
                                out_retransmitted: i.out_retransmitted60,
                                in_dropped: i.in_droped60,
                                out_dropped: i.out_droped60,
                            }
                        ],
                        short_interval: [
                            {
                                interface_id: i.interfaceId,
                                in_retransmitted: i.in_retransmitted10,
                                out_retransmitted: i.out_retransmitted10,
                                in_dropped: i.in_droped10,
                                out_dropped: i.out_droped10,
                            }
                        ]
                    }
                });
            }
        } while (summaries.data.listStreamManagerStatisticSummarys.nextToken !== null);
        console.log(this.statisticsSummary);
        this.statisticsSummaryUpdated += 1;
    }

    private unSubscribeServers() {
        this.interface_subscription.unsubscribe();
    }


    private async toggle(id: string) {
        this.$root.$emit('bv::toggle::collapse', `edit-collapse-${id}`);

        this.collapsed = !this.collapsed;
        if (!this.collapsed) {
            if (this.interfaces.length === 0) {
                await this.loadInterfaces();
            }

            if (this.settings.length === 0) {
                await this.loadSettings();
            }

            if (this.tags.length === 0) {
                await this.loadTags();
            }

            this.GetStreamManagerSummaries();

            this.subscribeServers();
        } else {
            this.unSubscribeServers();
        }
    }

   /* private isInterfaceVisible() {
        return true;
    }*/

/*    private addStatistics(interfaceItem: any, lowestTimestamp: string): string {
        const i = this.iInterfaces.find(inter => inter.id === interfaceItem.id);
        if (i) {
            // @ts-ignore
            interfaceItem.statistics.items.sort((l, r) => {
                if (new Date(l.timestamp) < new Date(r.timestamp)) {
                    return -1;
                } else if (new Date(l.timestamp) > new Date(r.timestamp)) {
                    return 1;
                }
                return 0;
            }).forEach((stat: any) => {
                if (new Date(stat.timestamp) < new Date(lowestTimestamp)) {
                    lowestTimestamp = stat.timestamp;
                }
                if(i && i.live.statistics.length == 0 || (i.live.statistics.length > 0 && new Date(i.live.statistics[i.live.statistics.length - 1].timestamp) < new Date(stat.timestamp))) {
                    i.live.statistics.push({
                        id: i.id,
                        name: i.name,
                        input: i.input,
                        // @ts-ignore
                        output: i.output,
                        type: i.type,
                        state: i.state,
                        auto_start: i.auto_start,
                        input_statistics: JSON.parse(stat.input_statistics),
                        output_statistics: JSON.parse(stat.output_statistics),
                        timestamp: stat.timestamp,
                        started_at: i.live.started_at,
                    });
                    while (i && i.live && i.live.statistics && i.live.statistics.length > 300) { // 1s between each point and 2.5 min of data
                        i.live.statistics.shift();
                    }
                }
            });
        }
        return lowestTimestamp;
    }*/

/*    private async loadStatistics() {
        let lowestTimestamp: string = '2100-01-01T00:00:00Z';
        let statistics = await this.getStatistics(this.Id, this.lastStatisticsTimestamps, null, null);
        // @ts-ignore
        if (statistics && statistics.data && statistics.data.getStreamManagerServer && statistics.data.getStreamManagerServer.interfaces.items) {
            // @ts-ignore
            //statistics.data.getStreamManagerServer.interfaces.items.forEach((interfaceItem) => {
            for(const interfaceItem of statistics.data.getStreamManagerServer.interfaces.items) {
                let nextToken = null;
                // @ts-ignore
                if (interfaceItem && interfaceItem.statistics && interfaceItem.statistics.items) {
                    lowestTimestamp = this.addStatistics(interfaceItem, lowestTimestamp);
                    nextToken = interfaceItem.statistics.nextToken;
                }
                
                while(nextToken !== null) {
                    let stats: any = await this.getStatisticsInterface(this.Id, this.lastStatisticsTimestamps, null, nextToken, interfaceItem.id);
                    // @ts-ignore
                    if (stats && stats.data && stats.data.getStreamManagerServer && statistics.data.getStreamManagerServer.interfaces && statistics.data.getStreamManagerServer.interfaces.items && statistics.data.getStreamManagerServer.interfaces.items.length > 0) {
                        // @ts-ignore
                        let ifItem = stats.data.getStreamManagerServer.interfaces.items[0];
                        if (ifItem && ifItem.statistics && ifItem.statistics.items) {
                            lowestTimestamp = this.addStatistics(ifItem, lowestTimestamp);
                            if (ifItem.statistics.nextToken === nextToken) {
                                alert("Why!!!");
                            }
                            nextToken = ifItem.statistics.nextToken;
                        } else {
                            nextToken = null;
                        }

                    } else {
                        nextToken = null;
                    }
                }
            };
        }
        if (new Date(this.lastStatisticsTimestamps) < new Date(lowestTimestamp)) {
            this.lastStatisticsTimestamps = lowestTimestamp;
        }
    }
*/
/*    private async getStatistics(serverId: string, timestamp: string, nextTokenInterfaces: string | null, nextTokenStatistics: string | null) {
        //
        // State 1 is running, 256 is larger that max number of expected servers
        //
        const getStatisticsQuery = / * GraphQL * / `query GetStreamManagerServer($serverId: ID!, $timestamp: String, $nextTokenStatistics: String, $nextTokenInterfaces: String) {
            getStreamManagerServer(id: $serverId) {
                interfaces(filter: { state: { eq:  1} }, limit: 256, nextToken: $nextTokenInterfaces ) {
                items {
                    id
                    name
                    auto_start
                    state
                    started
                    statistics(filter: { timestamp: { gt: $timestamp }}, limit: 300, nextToken: $nextTokenStatistics) {
                        items {
                            timestamp
                            input_statistics
                            output_statistics
                        }
                        nextToken
                    }
                }
                nextToken
                }
            }
        }`;

         return await API.graphql({
            query: getStatisticsQuery,
            variables: { 
                serverId: serverId,
                timestamp: timestamp,
                nextTokenStatistics: nextTokenStatistics,
                nextTokenInterfaces: nextTokenInterfaces,
            }
        });
    }
*/
/*       private async getStatisticsInterface(serverId: string, timestamp: string, nextTokenInterfaces: string | null, nextTokenStatistics: string | null, interfaceId: string | null) {
        //
        // State 1 is running, 256 is larger that max number of expected servers
        //
        const getStatisticsQuery = / * GraphQL * / `query GetStreamManagerServer($serverId: ID!, $timestamp: String, $nextTokenStatistics: String, $nextTokenInterfaces: String, $interfaceId: ID) {
            getStreamManagerServer(id: $serverId) {
                interfaces(filter: { state: { eq:  1}, id: { eq: $interfaceId} }, limit: 256, nextToken: $nextTokenInterfaces ) {
                items {
                    id
                    name
                    auto_start
                    state
                    started
                    statistics(filter: { timestamp: { gt: $timestamp }}, limit: 300, nextToken: $nextTokenStatistics) {
                        items {
                            timestamp
                            input_statistics
                            output_statistics
                        }
                        nextToken
                    }
                }
                nextToken
                }
            }
        }`;

         return await API.graphql({
            query: getStatisticsQuery,
            variables: { 
                serverId: serverId,
                timestamp: timestamp,
                nextTokenStatistics: nextTokenStatistics,
                nextTokenInterfaces: nextTokenInterfaces,
                interfaceId: interfaceId
            }
        });
    }*/
}
