






















































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import url from 'url';
import { parse } from 'path';

interface KeyValue {
    key: string;
    value: string;
}

@Component
export default class NDI extends Vue {
    private lurls: any[] = [];
    private parameters: { [key: string]: string } = {};
    private parameterBTName: string[] = [];
    private Ttl: number = 1;
    private pathname: string = '';
    private stream: string = '';
    @Prop({default: []}) private urls!: string[];
    @Prop({default: ''}) private mode!: string;
    @Prop({default: ''}) private id!: string;

    @Emit()
    private configurationChanged(newValue: string[]) {
        // dummy
    }

    @Emit()
    private typeChange(newUrl: string) {
        // dummy
    }

    @Watch('urls', { immediate: true })
    private onURLSChange(newValue: string[], oldValue: string[]) {
        if (newValue) {
            this.lurls = [];
            this.parameters = {};
            this.pathname = '';
            this.stream = '';
            let i: number = 0;

            newValue.forEach((u) => {
                const lurl: any = url.parse(u, false, false);
                this.lurls.push(lurl);

                if (0 === i) {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl !== null && lurl.query !== null && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl !== null && lurl.query !== null && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1) {
                                this.parameters[values[0].toLowerCase()] = values[1];
                            } else {
                                this.parameters[values[0].toLowerCase()] = 'true';
                            }
                        } else {
                            this.parameters[param.toLowerCase()] = 'true';
                        }
                    });
                }
                {
                    let params: string[] = [];
                    if (lurl && lurl.query && lurl.query.indexOf('&') !== -1) {
                        params = lurl.query.split('&');
                    } else if (lurl && lurl.query && lurl.query.indexOf('=') !== -1) {
                        params.push(lurl.query);
                    }
                    params.forEach((param) => {
                        if (param.indexOf('=') !== -1) {
                            const values = param.split('=');
                            if (values.length > 1 && values[0].toLowerCase() === 'bt_name') {
                                this.parameterBTName[i] = values[1];
                            }
                        }
                    });
                }
                i++;
            });
            const ttl = 'ttl';
            this.Ttl = parseInt(this.parameters[ttl], 10);
        }
    }

    private remove() {
        this.configurationChanged([]);
    }

    private removeAddress(index: number) {
        Vue.delete(this.lurls, index);
    }

    private addAddress() {
        Vue.set(this.lurls, this.lurls.length, '');
    }

    private Address(index: number): string {
        if (this.lurls && this.lurls.length > 0) {
            return this.lurls[index].hostname;
        }

        return '';
    }
    private Port(index: number, secure: boolean|null = null): number {
        if (this.lurls && this.lurls.length > 0) {
            if (this.lurls[index].port && this.lurls[index].port !== '') {
                if (secure === null || (secure !== null && parseInt(this.lurls[index].port, 10) !== 80 && parseInt(this.lurls[index].port, 10) !== 443)) {
                    return this.lurls[index].port;
                }
            }

            return 80;
        }

        return 0;
    }

    private get AudioDelay(): number {
        const param = 'audio-delay';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 0;
    }

     private get AudioChannel(): number {
        const param = 'audio-channel';
        if (this.parameters[param]) {
            return parseInt(this.parameters[param], 10);
        }

        return 1;
    }

    private get URLS(): string[] {
        return this.lurls;
    }
    private buildParameters(index: number = 0) {
        if (index > 0) {
            if (this.parameterBTName[index] && this.parameterBTName[index] !== '') {
                return 'bt_name=' + this.parameterBTName[index];
            }
            return '';
        }

        let ret: string = '';
        Object.keys(this.parameters).forEach(
            (key) => (ret += `${key}=${this.parameters[key]}&`),
        );

        return ret.substring(0, ret.length - 1);
    }
    private addressChanged(newValue: string, index: number = 0): void {
        if (newValue.indexOf(':') !== -1 && newValue.indexOf('://') === -1) {
            if (newValue.indexOf('?') !== -1) { // also apply settings, will overwrite current settings
                this.typeChange(newValue);
                return;
            } else { // just change address and port, rest of settings are retained
                const addressAndPort = newValue.split(':');
                this.portChanged(addressAndPort[1], index);
                newValue = addressAndPort[0];
            }
        } else if (newValue.indexOf('://') !== -1 && index === 0) {
            this.typeChange(newValue);
            return;
        }
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                // @ts-ignore
                `ndi://${newValue}?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);     }

    private portChanged(newValue: string, index: number = 0): void {
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(
                `ndi://${this.Address(index)}:${
                    // @ts-ignore
                    newValue
                }/${this.pathname}/${this.stream}?${this.buildParameters(index)}`,
                false,
                false,
            ),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private ttlChanged(newValue: string, index: number = 0): void {
        const ttl = 'ttl';
        // @ts-ignore
        Vue.set(this.parameters, ttl, newValue);
        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`ndi://${this.Address(index)}:${this.Port(index)}/${this.pathname}/${this.stream}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }

    private parameterChanged(newValue: string, parameter: string, index: number = 0) {
        if (index > 0) {
            Vue.set(this.parameterBTName, index, newValue);
        } else {
            if (parameter === 'bt_name' && newValue === '') {
                Vue.delete(this.parameters, parameter);
            } else {
                Vue.set(this.parameters, parameter, newValue);
            }
        }

        // @ts-ignore
        Vue.set(
            this.lurls,
            index,
            url.parse(`ndi://${this.Address(index)}?${this.buildParameters(index)}`, false, false),
        );

        const conf: string[] = [];
        this.lurls.forEach((u) => conf.push(u.href));

        this.configurationChanged(conf);
    }
}
