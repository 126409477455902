 <script>
      import {Line, mixins} from 'vue-chartjs'; // We specify what type of chart we want
                                                // from vue-chartjs and the mixins module
      const { reactiveProp } = mixins;

      export default {
        extends: Line,
        mixins: [reactiveProp],
        data() {
          return {
            options: { // Chart.js options
              elements: {
                point: {
                  radius: 0,
                },
              },
              scales: {
                yAxes: [{
                  id: 'drops',
                  position: 'right',
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: true,
                  },
                },
                {
                  id: 'bitrate',
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: true,
                  },
                },
                ],
                xAxes: [ {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                      display: false,
                    },
                }],
              },
              legend: {
                display: false,
              },
              responsive: true,
              maintainAspectRatio: false,
              animation: false,
            },
          };
        },
        mounted() {
          // this.chartData is created in the mixin
          this.renderChart(this.chartData, this.options);
          this.generateLegend();
        },

      };
    </script>
