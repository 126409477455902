/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      servers {
        items {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      servers {
        items {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      servers {
        items {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const createStreamManagerInterface = /* GraphQL */ `
  mutation CreateStreamManagerInterface(
    $input: CreateStreamManagerInterfaceInput!
    $condition: ModelStreamManagerInterfaceConditionInput
  ) {
    createStreamManagerInterface(input: $input, condition: $condition) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
      live {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      statisticSummary {
        id
        interfaceId
        timestamp
        serverId
        in_droped1
        in_retransmitted1
        in_bitrate1
        out_droped1
        out_retransmitted1
        out_bitrate1
        in_droped10
        in_retransmitted10
        in_bitrate10
        out_droped10
        out_retransmitted10
        out_bitrate10
        in_droped30
        in_retransmitted30
        in_bitrate30
        out_droped30
        out_retransmitted30
        out_bitrate30
        in_droped60
        in_retransmitted60
        in_bitrate60
        out_droped60
        out_retransmitted60
        out_bitrate60
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
    }
  }
`;
export const updateStreamManagerInterface = /* GraphQL */ `
  mutation UpdateStreamManagerInterface(
    $input: UpdateStreamManagerInterfaceInput!
    $condition: ModelStreamManagerInterfaceConditionInput
  ) {
    updateStreamManagerInterface(input: $input, condition: $condition) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
      live {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      statisticSummary {
        id
        interfaceId
        timestamp
        serverId
        in_droped1
        in_retransmitted1
        in_bitrate1
        out_droped1
        out_retransmitted1
        out_bitrate1
        in_droped10
        in_retransmitted10
        in_bitrate10
        out_droped10
        out_retransmitted10
        out_bitrate10
        in_droped30
        in_retransmitted30
        in_bitrate30
        out_droped30
        out_retransmitted30
        out_bitrate30
        in_droped60
        in_retransmitted60
        in_bitrate60
        out_droped60
        out_retransmitted60
        out_bitrate60
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
    }
  }
`;
export const deleteStreamManagerInterface = /* GraphQL */ `
  mutation DeleteStreamManagerInterface(
    $input: DeleteStreamManagerInterfaceInput!
    $condition: ModelStreamManagerInterfaceConditionInput
  ) {
    deleteStreamManagerInterface(input: $input, condition: $condition) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
      live {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
      statisticSummary {
        id
        interfaceId
        timestamp
        serverId
        in_droped1
        in_retransmitted1
        in_bitrate1
        out_droped1
        out_retransmitted1
        out_bitrate1
        in_droped10
        in_retransmitted10
        in_bitrate10
        out_droped10
        out_retransmitted10
        out_bitrate10
        in_droped30
        in_retransmitted30
        in_bitrate30
        out_droped30
        out_retransmitted30
        out_bitrate30
        in_droped60
        in_retransmitted60
        in_bitrate60
        out_droped60
        out_retransmitted60
        out_bitrate60
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        interface {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
      }
    }
  }
`;
export const createStreamManagerServer = /* GraphQL */ `
  mutation CreateStreamManagerServer(
    $input: CreateStreamManagerServerInput!
    $condition: ModelStreamManagerServerConditionInput
  ) {
    createStreamManagerServer(input: $input, condition: $condition) {
      id
      name
      description
      lastStatisticsTimestamp
      lastNotificationsTimestamp
      lastInterfaceStates
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      owner {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        servers {
          nextToken
        }
      }
      interfaces {
        items {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      settings {
        items {
          serverId
          key
          value
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      tags {
        items {
          serverId
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      commands {
        items {
          serverId
          id
          interfaceId
          command
          data
          creator
          state
          message
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const updateStreamManagerServer = /* GraphQL */ `
  mutation UpdateStreamManagerServer(
    $input: UpdateStreamManagerServerInput!
    $condition: ModelStreamManagerServerConditionInput
  ) {
    updateStreamManagerServer(input: $input, condition: $condition) {
      id
      name
      description
      lastStatisticsTimestamp
      lastNotificationsTimestamp
      lastInterfaceStates
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      owner {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        servers {
          nextToken
        }
      }
      interfaces {
        items {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      settings {
        items {
          serverId
          key
          value
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      tags {
        items {
          serverId
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      commands {
        items {
          serverId
          id
          interfaceId
          command
          data
          creator
          state
          message
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const deleteStreamManagerServer = /* GraphQL */ `
  mutation DeleteStreamManagerServer(
    $input: DeleteStreamManagerServerInput!
    $condition: ModelStreamManagerServerConditionInput
  ) {
    deleteStreamManagerServer(input: $input, condition: $condition) {
      id
      name
      description
      lastStatisticsTimestamp
      lastNotificationsTimestamp
      lastInterfaceStates
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      owner {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        servers {
          nextToken
        }
      }
      interfaces {
        items {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      settings {
        items {
          serverId
          key
          value
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      tags {
        items {
          serverId
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
      commands {
        items {
          serverId
          id
          interfaceId
          command
          data
          creator
          state
          message
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const createStreamManagerSetting = /* GraphQL */ `
  mutation CreateStreamManagerSetting(
    $input: CreateStreamManagerSettingInput!
    $condition: ModelStreamManagerSettingConditionInput
  ) {
    createStreamManagerSetting(input: $input, condition: $condition) {
      serverId
      key
      value
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const updateStreamManagerSetting = /* GraphQL */ `
  mutation UpdateStreamManagerSetting(
    $input: UpdateStreamManagerSettingInput!
    $condition: ModelStreamManagerSettingConditionInput
  ) {
    updateStreamManagerSetting(input: $input, condition: $condition) {
      serverId
      key
      value
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const deleteStreamManagerSetting = /* GraphQL */ `
  mutation DeleteStreamManagerSetting(
    $input: DeleteStreamManagerSettingInput!
    $condition: ModelStreamManagerSettingConditionInput
  ) {
    deleteStreamManagerSetting(input: $input, condition: $condition) {
      serverId
      key
      value
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const createStreamManagerTag = /* GraphQL */ `
  mutation CreateStreamManagerTag(
    $input: CreateStreamManagerTagInput!
    $condition: ModelStreamManagerTagConditionInput
  ) {
    createStreamManagerTag(input: $input, condition: $condition) {
      serverId
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const updateStreamManagerTag = /* GraphQL */ `
  mutation UpdateStreamManagerTag(
    $input: UpdateStreamManagerTagInput!
    $condition: ModelStreamManagerTagConditionInput
  ) {
    updateStreamManagerTag(input: $input, condition: $condition) {
      serverId
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const deleteStreamManagerTag = /* GraphQL */ `
  mutation DeleteStreamManagerTag(
    $input: DeleteStreamManagerTagInput!
    $condition: ModelStreamManagerTagConditionInput
  ) {
    deleteStreamManagerTag(input: $input, condition: $condition) {
      serverId
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const createStreamManagerCommand = /* GraphQL */ `
  mutation CreateStreamManagerCommand(
    $input: CreateStreamManagerCommandInput!
    $condition: ModelStreamManagerCommandConditionInput
  ) {
    createStreamManagerCommand(input: $input, condition: $condition) {
      serverId
      id
      interfaceId
      command
      data
      creator
      state
      message
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const updateStreamManagerCommand = /* GraphQL */ `
  mutation UpdateStreamManagerCommand(
    $input: UpdateStreamManagerCommandInput!
    $condition: ModelStreamManagerCommandConditionInput
  ) {
    updateStreamManagerCommand(input: $input, condition: $condition) {
      serverId
      id
      interfaceId
      command
      data
      creator
      state
      message
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const deleteStreamManagerCommand = /* GraphQL */ `
  mutation DeleteStreamManagerCommand(
    $input: DeleteStreamManagerCommandInput!
    $condition: ModelStreamManagerCommandConditionInput
  ) {
    deleteStreamManagerCommand(input: $input, condition: $condition) {
      serverId
      id
      interfaceId
      command
      data
      creator
      state
      message
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      server {
        id
        name
        description
        lastStatisticsTimestamp
        lastNotificationsTimestamp
        lastInterfaceStates
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        owner {
          id
          name
          description
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        interfaces {
          nextToken
        }
        settings {
          nextToken
        }
        tags {
          nextToken
        }
        commands {
          nextToken
        }
      }
    }
  }
`;
export const createStreamManagerLiveInterface = /* GraphQL */ `
  mutation CreateStreamManagerLiveInterface(
    $input: CreateStreamManagerLiveInterfaceInput!
    $condition: ModelStreamManagerLiveInterfaceConditionInput
  ) {
    createStreamManagerLiveInterface(input: $input, condition: $condition) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateStreamManagerLiveInterface = /* GraphQL */ `
  mutation UpdateStreamManagerLiveInterface(
    $input: UpdateStreamManagerLiveInterfaceInput!
    $condition: ModelStreamManagerLiveInterfaceConditionInput
  ) {
    updateStreamManagerLiveInterface(input: $input, condition: $condition) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteStreamManagerLiveInterface = /* GraphQL */ `
  mutation DeleteStreamManagerLiveInterface(
    $input: DeleteStreamManagerLiveInterfaceInput!
    $condition: ModelStreamManagerLiveInterfaceConditionInput
  ) {
    deleteStreamManagerLiveInterface(input: $input, condition: $condition) {
      id
      name
      description
      updated
      started
      srt_connection_limit
      auto_start
      tags
      nr
      state
      input
      output
      active_input
      avtive_output
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      version
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createRouter = /* GraphQL */ `
  mutation CreateRouter(
    $input: CreateRouterInput!
    $condition: ModelRouterConditionInput
  ) {
    createRouter(input: $input, condition: $condition) {
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      sources {
        items {
          id
          group
          name
          address
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRouter = /* GraphQL */ `
  mutation UpdateRouter(
    $input: UpdateRouterInput!
    $condition: ModelRouterConditionInput
  ) {
    updateRouter(input: $input, condition: $condition) {
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      sources {
        items {
          id
          group
          name
          address
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRouter = /* GraphQL */ `
  mutation DeleteRouter(
    $input: DeleteRouterInput!
    $condition: ModelRouterConditionInput
  ) {
    deleteRouter(input: $input, condition: $condition) {
      id
      name
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      sources {
        items {
          id
          group
          name
          address
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRouterSource = /* GraphQL */ `
  mutation CreateRouterSource(
    $input: CreateRouterSourceInput!
    $condition: ModelRouterSourceConditionInput
  ) {
    createRouterSource(input: $input, condition: $condition) {
      id
      group
      name
      address
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRouterSource = /* GraphQL */ `
  mutation UpdateRouterSource(
    $input: UpdateRouterSourceInput!
    $condition: ModelRouterSourceConditionInput
  ) {
    updateRouterSource(input: $input, condition: $condition) {
      id
      group
      name
      address
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRouterSource = /* GraphQL */ `
  mutation DeleteRouterSource(
    $input: DeleteRouterSourceInput!
    $condition: ModelRouterSourceConditionInput
  ) {
    deleteRouterSource(input: $input, condition: $condition) {
      id
      group
      name
      address
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      destinations {
        items {
          id
          name
          group
          revision
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRouterDestination = /* GraphQL */ `
  mutation CreateRouterDestination(
    $input: CreateRouterDestinationInput!
    $condition: ModelRouterDestinationConditionInput
  ) {
    createRouterDestination(input: $input, condition: $condition) {
      id
      name
      group
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      source {
        id
        group
        name
        address
        revision
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        router {
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        destinations {
          nextToken
        }
      }
    }
  }
`;
export const updateRouterDestination = /* GraphQL */ `
  mutation UpdateRouterDestination(
    $input: UpdateRouterDestinationInput!
    $condition: ModelRouterDestinationConditionInput
  ) {
    updateRouterDestination(input: $input, condition: $condition) {
      id
      name
      group
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      source {
        id
        group
        name
        address
        revision
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        router {
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        destinations {
          nextToken
        }
      }
    }
  }
`;
export const deleteRouterDestination = /* GraphQL */ `
  mutation DeleteRouterDestination(
    $input: DeleteRouterDestinationInput!
    $condition: ModelRouterDestinationConditionInput
  ) {
    deleteRouterDestination(input: $input, condition: $condition) {
      id
      name
      group
      revision
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      router {
        id
        name
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        sources {
          nextToken
        }
        destinations {
          nextToken
        }
      }
      source {
        id
        group
        name
        address
        revision
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        router {
          id
          name
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
        destinations {
          nextToken
        }
      }
    }
  }
`;
export const createStreamManagerStatisticSummary = /* GraphQL */ `
  mutation CreateStreamManagerStatisticSummary(
    $input: CreateStreamManagerStatisticSummaryInput!
    $condition: ModelStreamManagerStatisticSummaryConditionInput
  ) {
    createStreamManagerStatisticSummary(input: $input, condition: $condition) {
      id
      interfaceId
      timestamp
      serverId
      in_droped1
      in_retransmitted1
      in_bitrate1
      out_droped1
      out_retransmitted1
      out_bitrate1
      in_droped10
      in_retransmitted10
      in_bitrate10
      out_droped10
      out_retransmitted10
      out_bitrate10
      in_droped30
      in_retransmitted30
      in_bitrate30
      out_droped30
      out_retransmitted30
      out_bitrate30
      in_droped60
      in_retransmitted60
      in_bitrate60
      out_droped60
      out_retransmitted60
      out_bitrate60
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateStreamManagerStatisticSummary = /* GraphQL */ `
  mutation UpdateStreamManagerStatisticSummary(
    $input: UpdateStreamManagerStatisticSummaryInput!
    $condition: ModelStreamManagerStatisticSummaryConditionInput
  ) {
    updateStreamManagerStatisticSummary(input: $input, condition: $condition) {
      id
      interfaceId
      timestamp
      serverId
      in_droped1
      in_retransmitted1
      in_bitrate1
      out_droped1
      out_retransmitted1
      out_bitrate1
      in_droped10
      in_retransmitted10
      in_bitrate10
      out_droped10
      out_retransmitted10
      out_bitrate10
      in_droped30
      in_retransmitted30
      in_bitrate30
      out_droped30
      out_retransmitted30
      out_bitrate30
      in_droped60
      in_retransmitted60
      in_bitrate60
      out_droped60
      out_retransmitted60
      out_bitrate60
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteStreamManagerStatisticSummary = /* GraphQL */ `
  mutation DeleteStreamManagerStatisticSummary(
    $input: DeleteStreamManagerStatisticSummaryInput!
    $condition: ModelStreamManagerStatisticSummaryConditionInput
  ) {
    deleteStreamManagerStatisticSummary(input: $input, condition: $condition) {
      id
      interfaceId
      timestamp
      serverId
      in_droped1
      in_retransmitted1
      in_bitrate1
      out_droped1
      out_retransmitted1
      out_bitrate1
      in_droped10
      in_retransmitted10
      in_bitrate10
      out_droped10
      out_retransmitted10
      out_bitrate10
      in_droped30
      in_retransmitted30
      in_bitrate30
      out_droped30
      out_retransmitted30
      out_bitrate30
      in_droped60
      in_retransmitted60
      in_bitrate60
      out_droped60
      out_retransmitted60
      out_bitrate60
      claimsCanAccess
      claimsCanEdit
      createdAt
      updatedAt
      interface {
        id
        name
        description
        updated
        started
        srt_connection_limit
        auto_start
        tags
        nr
        state
        input
        output
        active_input
        avtive_output
        claimsCanAccess
        claimsCanEdit
        createdAt
        updatedAt
        version
        server {
          id
          name
          description
          lastStatisticsTimestamp
          lastNotificationsTimestamp
          lastInterfaceStates
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        live {
          id
          name
          description
          updated
          started
          srt_connection_limit
          auto_start
          tags
          nr
          state
          input
          output
          active_input
          avtive_output
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          version
        }
        statisticSummary {
          id
          interfaceId
          timestamp
          serverId
          in_droped1
          in_retransmitted1
          in_bitrate1
          out_droped1
          out_retransmitted1
          out_bitrate1
          in_droped10
          in_retransmitted10
          in_bitrate10
          out_droped10
          out_retransmitted10
          out_bitrate10
          in_droped30
          in_retransmitted30
          in_bitrate30
          out_droped30
          out_retransmitted30
          out_bitrate30
          in_droped60
          in_retransmitted60
          in_bitrate60
          out_droped60
          out_retransmitted60
          out_bitrate60
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
        }
      }
    }
  }
`;
