




























import { Component, Vue, Prop } from 'vue-property-decorator';

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';



@Component
export default class GeoLocationsComponent extends Vue {
    // @ts-ignore
    @Prop() private geoip: IGeoIP | undefined;
    @Prop() private ip: string | undefined;

    private get Success(): boolean {
        return this.geoip && this.geoip.status === 'success';
    }

    private get RegionName(): string {
        if (this.Success) {
            return this.geoip.regionName;
        }

        return '';
    }

    private get CountryCode(): string {
        if (this.Success) {
            return this.geoip.countryCode.toLowerCase();
        }

        return '';
    }

    private get City(): string {
        if (this.Success) {
            return this.geoip.city;
        }

        return '';
    }

    private get Mobile(): boolean {
        if (this.Success) {
            return this.geoip.mobile;
        }

        return false;
    }
}
