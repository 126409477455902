




















































import { Component, Prop, Vue, Emit  } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import {IInterface} from '../services/models/Interface';
import {IStatus} from '../services/models/Status';
import {IGeoIP} from '../services/models/GeoIP';

interface GraphColour {
    backgroundColor: string;
    borderColor: string;
    pointColor: string;
    pointStrokeColor: string;
    pointHighlightFill: string;
    pointHighlightStroke: string;
}

@Component({
    components: {
        // @ts-ignore
        StatisticsComponent: require('./StatisticsGraphDetail').default,
        // @ts-ignore
        GeoLocationsComponent: require('./GeoLocation').default,
    },
    computed: {
        ...mapState(['locations']),
    },
    methods: {
        ...mapActions(['loadGeoIP']),
    },
})
// @ts-ignore
export default class StatisticsDetail extends Vue {
    // @ts-ignore
    @Prop() private interfaceObject: IInterface;

    private locations!: { [key: string]: IGeoIP };
    private lastUpdate: string = '';
    private labels: string[] = [];
    private inputsBandwidth: { [key: string]: string[] } = {};
    private outputsLatancy: { [key: string]: { [key: string]: number|undefined } } = {};
    private inputsLatancy: { [key: string]: number|undefined } = {};
    private outputsVersion: { [key: string]: { [key: string]: number|undefined } } = {};
    private inputsVersion: { [key: string]: number|undefined } = {};

    private inputsDrops: { [key: string]: number[] } = {};
    private inputsRetransmitted: { [key: string]: number[] } = {};
    private inputsLost: { [key: string]: number[] } = {};

    private outputBandwidth: { [key: string]: { [key: string]: string[] } } = {};
    private outputDrops: { [key: string]: { [key: string]: number[] } } = {};
    private outputLabels: { [key: string]: string[] } = {};
    private outputRetransmitted: { [key: string]: { [key: string]: number[] }  } = {};
    private outputLost: { [key: string]: { [key: string]: number[] } } = {};

    private inputsBandwidthColour: { [key: string]: GraphColour } = {};
    private inputsDropColour: { [key: string]: GraphColour } = {};
    private inputsRetransmittedColour: { [key: string]: GraphColour } = {};
    private inputsLostColour: { [key: string]: GraphColour } = {};

    private outputsBandwidthColour: { [key: string]: { [key: string]: GraphColour } } = {};
    private outputsDropColour: { [key: string]: { [key: string]: GraphColour } } = {};
    private outputsRetransmittedColour: { [key: string]: { [key: string]: GraphColour } } = {};
    private outputsLostColour: { [key: string]: { [key: string]: GraphColour } } = {};

    private GraphDataInputColours: GraphColour[] = [];

    private GraphDataOutputColours: GraphColour[] = [];

    private GraphDropColours: GraphColour[] = [];

    private GraphDataInputBaseColour: number = 220;

    private GraphDataOutputBaseRedColour: number = 161;
    private GraphDataOutputBaseGreenColour: number = 248;
    private GraphDataOutputBaseBlueColour: number = 191;

    private GraphDropBaseRedColour: number = 250;
    private GraphDropBaseGreenColour: number = 128;
    private GraphDropBaseBlueColour: number = 114;

    private GraphLostBaseRedColour: number = 250;
    private GraphLostBaseGreenColour: number = 128;
    private GraphLostBaseBlueColour: number = 114;

    private GraphRetransmittedBaseRedColour: number = 128;
    private GraphRetransmittedBaseGreenColour: number = 128;
    private GraphRetransmittedBaseBlueColour: number = 114;

    private GraphLostColours: GraphColour[] = [];

    private GraphRetransmittedColours: GraphColour[] = [];

    private inputStatistics: any = {
                    labels: [],
                    datasets: [],
            };

    private outputStatistics: { [key: string]: any } = {};

    public get GraphStylesInput(): any {
        let height: number = 160;

        if (this.inputStatistics.datasets.length > 4) {
            height += (this.inputStatistics.datasets.length - 4) * 10;
        }

        return {
            height: `${height}px`,
        };
    }

    public get GraphStyles(): any {
        const height: number = 160;

        return {
            height: `${height}px`,
        };
    }

    private get Locations(): { [key: string]: string } {
        const ret: { [key: string]: string } = {};
        const tmp: any = this.lastUpdate;

        Object.keys(this.inputsBandwidth).forEach((k) => {
            let r: string = k;
            if (k && k.indexOf(':') !== -1) {
                r = (k.split(':')[0]);
                if (r[0] === '(') {
                    r = r.substr(1);
                }
            }
            if (r.length >= 7 && ret[r] === undefined) {
                if (this.locations[r] === undefined) {
                     // @ts-ignore
                     this.loadGeoIP(r);
                }
                ret[r] = r;
            }
        });

        Object.keys(this.outputBandwidth).forEach((l) => {
           Object.keys(this.outputBandwidth[l]).forEach((k) => {
                let r: string = k;
                if (k && k.indexOf(':') !== -1) {
                    r = (k.split(':')[0]);
                    if (r[0] === '(') {
                        r = r.substr(1);
                    }
                }
                if (ret[r] === undefined) {
                    if (this.locations[r] === undefined) {
                        // @ts-ignore
                        this.loadGeoIP(r);
                    }
                    ret[r] = r;
                }
            });
        });

        return ret;
    }

    private GetGeo(ip: string): IGeoIP| undefined {
        return this.locations[ip];
    }

    private InitColours(): void {
        this.GraphDataInputColours = [];
        this.GraphDataOutputColours = [];
        this.GraphDropColours = [];
        this.GraphLostColours = [];
        this.GraphRetransmittedColours = [];

        for (let i: number = 16; i >= 0 ; i--) {
            const colour: number = this.GraphDataInputBaseColour - (i * 10);
            this.GraphDataInputColours.push(
            {
                backgroundColor: `rgba(${colour}, ${colour}, ${colour}, 0.2)`,
                borderColor: `rgba(${colour}, ${colour}, ${colour}, 1)`,
                pointColor: `rgba(${colour}, ${colour}, ${colour}, 1)`,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: `rgba(${colour}, ${colour}, ${colour},1)`,
            });

            const outputRedColour = this.GraphDataOutputBaseRedColour - (i * 10);
            const outputGreenColour = this.GraphDataOutputBaseGreenColour - (i * 10);
            const outputBlueColour = this.GraphDataOutputBaseBlueColour - (i * 10);
            this.GraphDataOutputColours.push(
            {
                backgroundColor: `rgba(${outputRedColour}, ${outputGreenColour}, ${outputBlueColour}, 0.2)`,
                borderColor: `rgba(${outputRedColour}, ${outputGreenColour}, ${outputBlueColour}, 1)`,
                pointColor: `rgba(${outputRedColour}, ${outputGreenColour}, ${outputBlueColour}, 1)`,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: `rgba(${outputRedColour}, ${outputGreenColour}, ${outputBlueColour}, 1)`,
            });

            const dropRedColour: number = this.GraphDropBaseRedColour - (i * 10);
            const dropGreenColour: number = this.GraphDropBaseGreenColour - (i * 10);
            const dropBlueColour: number = this.GraphDropBaseBlueColour - (i * 5);
            this.GraphDropColours.push(
            {
                backgroundColor: `rgba(${dropRedColour}, ${dropGreenColour}, ${dropBlueColour}, 0.2)`,
                borderColor: `rgba(${dropRedColour}, ${dropGreenColour}, ${dropBlueColour}, 1)`,
                pointColor: `rgba(${dropRedColour}, ${dropGreenColour}, ${dropBlueColour}, 1)`,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: `rgba(${dropRedColour}, ${dropGreenColour}, ${dropBlueColour}, 1)`,
            });

            const lostRedColour: number = this.GraphLostBaseRedColour - (i * 10);
            const lostGreenColour: number = this.GraphLostBaseGreenColour - (i * 10);
            const lostBlueColour: number = this.GraphLostBaseBlueColour - (i * 5);
            this.GraphLostColours.push(
            {
                backgroundColor: `rgba(${lostRedColour}, ${lostGreenColour}, ${lostBlueColour}, 0.2)`,
                borderColor: `rgba(${lostRedColour}, ${lostGreenColour}, ${lostBlueColour}, 1)`,
                pointColor: `rgba(${lostRedColour}, ${lostGreenColour}, ${lostBlueColour}, 1)`,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: `rgba(${lostRedColour}, ${lostGreenColour}, ${lostBlueColour}, 1)`,
            });

            const retransmittedRedColour: number = this.GraphRetransmittedBaseRedColour - (i * 10);
            const retransmittedGreenColour: number = this.GraphRetransmittedBaseGreenColour - (i * 10);
            const retransmittedBlueColour: number = this.GraphRetransmittedBaseBlueColour - (i * 5);
            this.GraphRetransmittedColours.push(
            {
                backgroundColor: `rgba(${retransmittedRedColour}, ${retransmittedGreenColour}, ${retransmittedBlueColour}, 0.2)`,
                borderColor: `rgba(${retransmittedRedColour}, ${retransmittedGreenColour}, ${retransmittedBlueColour}, 1)`,
                pointColor: `rgba(${retransmittedRedColour}, ${retransmittedGreenColour}, ${retransmittedBlueColour}, 1)`,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: `rgba(${retransmittedRedColour}, ${retransmittedGreenColour}, ${retransmittedBlueColour}, 1)`,
            });

        }
    }

    private toVersionString(version: number): string {
        /* tslint:disable:no-bitwise */
        const build: number = version & 0xFF;
        const minor: number = (version >> 8) & 0xFF;
        const major: number = (version >> 16) & 0xFF;
        /* tslint:enable:no-bitwise */

        return major + '.' + minor + '.' + build;
    }

    private mounted() {
        setInterval(() => {
            if (this.interfaceObject && this.interfaceObject.live && this.interfaceObject.live.statistics && this.interfaceObject.live.statistics.length > 0 &&
                this.interfaceObject.live.statistics[this.interfaceObject.live.statistics.length - 1].timestamp
                !== this.lastUpdate) {
                Object.keys(this.inputsBandwidth).forEach((key) => {
                    this.inputsBandwidth[key] = [];
                    this.inputsDrops[key] = [];
                    this.inputsRetransmitted[key] = [];
                    this.inputsLost[key] = [];
                    this.labels = [];
                });

                Object.keys(this.outputBandwidth).forEach((key) => {
                    Object.keys(this.outputBandwidth[key]).forEach((oKey) => {
                        this.outputBandwidth[key][oKey] = [];
                        this.outputDrops[key][oKey] = [];
                        this.outputLabels[key] = [];
                        this.outputRetransmitted[key][oKey] = [];
                        this.outputLost[key][oKey] = [];
                    });
                });

                /*Input stacked grapg */
                this.interfaceObject.live.statistics.forEach((s) => {
                    let added: boolean = false;
                    if (s && s.input_statistics) {
                        s!.input_statistics!.forEach((is) => {
                            if (is && is.data) {
                                is!.data!.forEach((isd) => {
                                    if (this.inputsBandwidth[is.name + '(' + isd.client + ')'] === undefined) {

                                        if (this.GraphDataInputColours.length === 0) {
                                            this.InitColours(); // duplicates I know, but better then an error
                                        }

                                        this.inputsBandwidth[is.name + '(' + isd.client + ')'] = [];
                                        this.inputsDrops[is.name + '(' + isd.client + ')'] = [];
                                        this.inputsRetransmitted[is.name + '(' + isd.client + ')'] = [];
                                        this.inputsLost[is.name + '(' + isd.client + ')'] = [];

                                        if (this.labels.length > 0) {
                                            while (this.inputsBandwidth[is.name + '(' + isd.client + ')'].length < this.labels.length) {
                                                this.inputsBandwidth[is.name + '(' + isd.client + ')'].push('0');
                                                this.inputsDrops[is.name + '(' + isd.client + ')'].push(0);
                                                this.inputsRetransmitted[is.name + '(' + isd.client + ')'].push(0);
                                                this.inputsLost[is.name + '(' + isd.client + ')'].push(0);
                                            }
                                        }
                                        // @ts-ignore
                                        this.inputsBandwidthColour[is.name + '(' + isd.client + ')'] = this.GraphDataInputColours.pop();
                                        // @ts-ignore
                                        this.inputsDropColour[is.name + '(' + isd.client + ')'] = this.GraphDropColours.pop();
                                        // @ts-ignore
                                        this.inputsRetransmittedColour[is.name + '(' + isd.client + ')'] = this.GraphRetransmittedColours.pop();
                                        // @ts-ignore
                                        this.inputsLostColour[is.name + '(' + isd.client + ')'] = this.GraphLostColours.pop();
                                    }

                                    while (this.inputsBandwidth[is.name + '(' + isd.client + ')'].length < this.labels.length) {
                                        this.inputsBandwidth[is.name + '(' + isd.client + ')'].push('0');
                                        this.inputsDrops[is.name + '(' + isd.client + ')'].push(0);
                                        this.inputsRetransmitted[is.name + '(' + isd.client + ')'].push(0);
                                        this.inputsLost[is.name + '(' + isd.client + ')'].push(0);
                                    }

                                    this.inputsBandwidth[is.name + '(' + isd.client + ')'].push(isd.recv.mbitRate.toFixed(2));
                                    this.inputsDrops[is.name + '(' + isd.client + ')'].push(isd.recv.packetsDropped);
                                    this.inputsRetransmitted[is.name + '(' + isd.client + ')'].push(isd.recv.packetsRetransmitted);
                                    this.inputsLost[is.name + '(' + isd.client + ')'].push(isd.recv.packetsLost);
                                    this.inputsLatancy[is.name + '(' + isd.client + ')'] = isd.latency;
                                    this.inputsVersion[is.name + '(' + isd.client + ')'] = isd.version;

                                    added = true;

                                /* iRate += isd.recv.mbitRate;
                                    iDrops += isd.recv.packetsDropped;*/
                                });
                            }
                        });
                    }

                    if (s && s.output_statistics) {
                        s!.output_statistics!.forEach((os) => {
                            if (this.outputBandwidth[os.socket] === undefined) {
                                this.outputBandwidth[os.socket] = {};
                                this.outputDrops[os.socket] = {};
                                this.outputRetransmitted[os.socket] = {};
                                this.outputLost[os.socket] = {};
                                this.outputsBandwidthColour[os.socket] = {};
                                this.outputsDropColour[os.socket] = {};
                                this.outputsRetransmittedColour[os.socket] = {};
                                this.outputsLostColour[os.socket] = {};
                                this.outputLabels[os.socket] = [];
                                this.outputsLatancy[os.socket] = {};
                                this.outputsVersion[os.socket] = {};
                            }
                            let outputAdded: boolean = false;
                            os!.data!.forEach((isd) => {
                                if (this.outputBandwidth[os.socket][isd.client] === undefined) {
                                    if (this.GraphDataOutputColours.length === 0) {
                                        this.InitColours(); // duplicates I know, but better then an error
                                    }

                                    this.outputBandwidth[os.socket][isd.client] = [];
                                    this.outputDrops[os.socket][isd.client] = [];
                                    this.outputRetransmitted[os.socket][isd.client] = [];
                                    this.outputLost[os.socket][isd.client] = [];

                                    if (this.outputLabels[os.socket].length > 0) {
                                        while (this.outputBandwidth[os.socket][isd.client].length < this.outputLabels[os.socket].length) {
                                            this.outputBandwidth[os.socket][isd.client].push('0');
                                            this.outputDrops[os.socket][isd.client].push(0);
                                            this.outputRetransmitted[os.socket][isd.client].push(0);
                                            this.outputLost[os.socket][isd.client].push(0);
                                        }
                                    }
                                    // @ts-ignore
                                    this.outputsBandwidthColour[os.socket][isd.client] = this.GraphDataOutputColours.pop();
                                    // @ts-ignore
                                    this.outputsDropColour[os.socket][isd.client] = this.GraphDropColours.pop();
                                    // @ts-ignore
                                    this.outputsRetransmittedColour[os.socket][isd.client] = this.GraphDataOutputColours.pop();
                                    // @ts-ignore
                                    this.outputsLostColour[os.socket][isd.client] = this.GraphLostColours.pop();
                                }

                                while (this.outputBandwidth[os.socket][isd.client].length < this.outputLabels[os.socket].length) {
                                    this.outputBandwidth[os.socket][isd.client].push('0');
                                    this.outputDrops[os.socket][isd.client].push(0);
                                    this.outputRetransmitted[os.socket][isd.client].push(0);
                                    this.outputLost[os.socket][isd.client].push(0);
                                }

                                this.outputBandwidth[os.socket][isd.client].push(isd.send.mbitRate.toFixed(2));
                                this.outputDrops[os.socket][isd.client].push(isd.send.packetsDropped);
                                this.outputRetransmitted[os.socket][isd.client].push(isd.send.packetsRetransmitted);
                                this.outputLost[os.socket][isd.client].push(isd.send.packetsLost);
                                this.outputsLatancy[os.socket][isd.client] = isd.latency;
                                this.outputsVersion[os.socket][isd.client] = isd.version;

                                outputAdded = true;
                               /* iRate += isd.recv.mbitRate;
                                iDrops += isd.recv.packetsDropped;*/
                            });

                            if (outputAdded && s.timestamp) {
                                 this.outputLabels[os.socket].push(s.timestamp.substring(11) || '');
                            }
                        });
                    }

                   /* this.input[s.name].push(iRate.toFixed(2)); // summery of valueas in this graph
                    this.iDrops.push(iDrops);*/
                    if (added && s.timestamp) {
                        this.labels.push(s.timestamp.substring(11) || '');
                    }
                });

                this.inputStatistics = {
                    labels: this.labels,
                    datasets: [
                    ],
                };

                Object.keys(this.outputBandwidth).forEach((key) => {
                   /* this.outputStatistics[key].labels = this.outputLabels[key];
                    this.outputStatistics[key].datasets = [];*/
                    this.outputStatistics[key] = {
                        labels: this.outputLabels[key],
                        datasets: [
                        ],
                    };
                });

                Object.keys(this.inputsBandwidth).forEach((key) => {
                    this.inputStatistics.datasets.push({
                        label: key + ' Bandwith',
                        yAxisID: 'bitrate',
                        backgroundColor: this.inputsBandwidthColour[key].backgroundColor,
                        borderColor: this.inputsBandwidthColour[key].borderColor,
                        pointColor: this.inputsBandwidthColour[key].pointColor,
                        pointStrokeColor: this.inputsBandwidthColour[key].pointStrokeColor,
                        pointHighlightFill: this.inputsBandwidthColour[key].pointHighlightFill,
                        pointHighlightStroke: this.inputsBandwidthColour[key].pointHighlightStroke,
                        data: this.inputsBandwidth[key],
                    });

                    this.inputStatistics.datasets.push({
                        label: key + ' Drops',
                        yAxisID: 'drops',
                        backgroundColor: this.inputsDropColour[key].backgroundColor,
                        borderColor: this.inputsDropColour[key].borderColor,
                        pointColor: this.inputsDropColour[key].pointColor,
                        pointStrokeColor: this.inputsDropColour[key].pointStrokeColor,
                        pointHighlightFill: this.inputsDropColour[key].pointHighlightFill,
                        pointHighlightStroke: this.inputsDropColour[key].pointHighlightStroke,
                        data: this.inputsDrops[key],
                    });

                    this.inputStatistics.datasets.push({
                        label: key + ' Retransmitted',
                        yAxisID: 'drops',
                        backgroundColor: this.inputsRetransmittedColour[key].backgroundColor,
                        borderColor: this.inputsRetransmittedColour[key].borderColor,
                        pointColor: this.inputsRetransmittedColour[key].pointColor,
                        pointStrokeColor: this.inputsRetransmittedColour[key].pointStrokeColor,
                        pointHighlightFill: this.inputsRetransmittedColour[key].pointHighlightFill,
                        pointHighlightStroke: this.inputsRetransmittedColour[key].pointHighlightStroke,
                        data: this.inputsRetransmitted[key],
                    });

                    this.inputStatistics.datasets.push({
                        label: key + ' Lost',
                        yAxisID: 'drops',
                        backgroundColor: this.inputsLostColour[key].backgroundColor,
                        borderColor: this.inputsLostColour[key].borderColor,
                        pointColor: this.inputsLostColour[key].pointColor,
                        pointStrokeColor: this.inputsLostColour[key].pointStrokeColor,
                        pointHighlightFill: this.inputsLostColour[key].pointHighlightFill,
                        pointHighlightStroke: this.inputsLostColour[key].pointHighlightStroke,
                        data: this.inputsLost[key],
                    });
                });

                Object.keys(this.outputBandwidth).forEach((key) => {
                    if (this.outputStatistics[key] === undefined) {
                        this.outputStatistics[key] =  {
                            labels: this.outputLabels[key],
                            datasets: [],
                        };
                    }

                    Object.keys(this.outputBandwidth[key]).forEach((oKey) => {
                        this.outputStatistics[key].datasets.push({
                            label: oKey + ' Bandwith',
                            yAxisID: 'bitrate',
                            backgroundColor: this.outputsBandwidthColour[key][oKey].backgroundColor,
                            borderColor: this.outputsBandwidthColour[key][oKey].borderColor,
                            pointColor: this.outputsBandwidthColour[key][oKey].pointColor,
                            pointStrokeColor: this.outputsBandwidthColour[key][oKey].pointStrokeColor,
                            pointHighlightFill: this.outputsBandwidthColour[key][oKey].pointHighlightFill,
                            pointHighlightStroke: this.outputsBandwidthColour[key][oKey].pointHighlightStroke,
                            data: this.outputBandwidth[key][oKey],
                        });

                        this.outputStatistics[key].datasets.push({
                            label: oKey + ' Drops',
                            yAxisID: 'drops',
                            backgroundColor: this.outputsDropColour[key][oKey].backgroundColor,
                            borderColor: this.outputsDropColour[key][oKey].borderColor,
                            pointColor: this.outputsDropColour[key][oKey].pointColor,
                            pointStrokeColor: this.outputsDropColour[key][oKey].pointStrokeColor,
                            pointHighlightFill: this.outputsDropColour[key][oKey].pointHighlightFill,
                            pointHighlightStroke: this.outputsDropColour[key][oKey].pointHighlightStroke,
                            data: this.outputDrops[key][oKey],
                        });

                        this.outputStatistics[key].datasets.push({
                            label: oKey + ' Retransmitted',
                            yAxisID: 'drops',
                            backgroundColor: this.outputsRetransmittedColour[key][oKey].backgroundColor,
                            borderColor: this.outputsRetransmittedColour[key][oKey].borderColor,
                            pointColor: this.outputsRetransmittedColour[key][oKey].pointColor,
                            pointStrokeColor: this.outputsRetransmittedColour[key][oKey].pointStrokeColor,
                            pointHighlightFill: this.outputsRetransmittedColour[key][oKey].pointHighlightFill,
                            pointHighlightStroke: this.outputsRetransmittedColour[key][oKey].pointHighlightStroke,
                            data: this.outputRetransmitted[key][oKey],
                        });

                        this.outputStatistics[key].datasets.push({
                            label: oKey + ' Lost',
                            yAxisID: 'drops',
                            backgroundColor: this.outputsLostColour[key][oKey].backgroundColor,
                            borderColor: this.outputsLostColour[key][oKey].borderColor,
                            pointColor: this.outputsLostColour[key][oKey].pointColor,
                            pointStrokeColor: this.outputsLostColour[key][oKey].pointStrokeColor,
                            pointHighlightFill: this.outputsLostColour[key][oKey].pointHighlightFill,
                            pointHighlightStroke: this.outputsLostColour[key][oKey].pointHighlightStroke,
                            data: this.outputLost[key][oKey],
                        });

                    });


                });

                this.lastUpdate = this.interfaceObject.live.statistics[this.interfaceObject.live.statistics.length - 1].timestamp || '';
            }
        }, 500);
    }

}
