

































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

@Component({
   
   computed: {
            ...mapState('AuthModule',['email']),
        },
        methods: {
            ...mapActions('AuthModule', ['getUser', 'signOut']),
        },
})
export default class Servers extends Vue {
    private email!: string;

    private mounted() {
        if (this.email === undefined) {
            // @ts-ignore
            this.getUser();
        }

        
    }

    private logout() {
        // @ts-ignore
        this.signOut();
    }



    private get Username(): string {
        if (this.email) {
            return this.email;
        }

        return '';
    }
}
