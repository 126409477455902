














































































































































































































































































import { Component, Prop, Vue, Emit  } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import {IInterface, IMultipleOutputs, ITag} from '../services/models/Interface';
import {IStatus} from '../services/models/Status';
import {IServer, IProcess} from '../services/models/Server';
import {ILicences} from '../services/models/Licences';
import {IAllowBlockList} from '../services/models/AllowBlockList';
import {ISetting} from '../services/models/Setting';
import {IRecording} from '../services/models/Recording';
import {IStatisticsSummary, IStatisticsSummaryDetail} from '../services/models/StatisticsSummaries';
import {ICinegyRouteSource} from '../services/models/CinegyRouteSource';

import url from 'url';


@Component({
    components: {
        // @ts-ignore
        StatisticsComponent: require('./StatisticsGraph').default,
         // @ts-ignore
        StatisticsDetailComponent: require('./StatisticsDetail').default,
        // @ts-ignore
        UDPComponent : require('./UDP').default,
        // @ts-ignore
        SRTComponent : require('./SRT').default,
        // @ts-ignore
        RTMPComponent : require('./RTMP').default,
        // @ts-ignore
        RISTComponent : require('./RIST').default,
        // @ts-ignore
        CinegyRouteComponent : require('./CinegyRouteInput').default,
        // @ts-ignore
        FileComponent : require('./File').default,
        // @ts-ignore
        MultiComponent : require('./MultipleOutputs').default,
        // @ts-ignore
        HLSComponent : require('./HLS').default,
        // @ts-ignore
        RelayComponent : require('./Relay').default,
        // @ts-ignore
        TUDPComponent : require('./TUDP').default,
        // @ts-ignore
        NDIComponent : require('./NDI').default,
    },
    computed: {
        ...mapState(['allowBlockLists', 'statisticsSummaryUpdated', 'serverStatus' ]),
    },
    methods: {
        ...mapActions(['updateSettings']),
    },
})
// @ts-ignore
export default class InterfaceComponent extends Vue {
    private serverStatus!: IServer;
    private updateInterval: number = -1;
    private updateInProgress: boolean = false;
    private statisticsSummaryUpdated!: Date;

    private outputCount: number = 0;
    private inputCount: number = 0;

    // @ts-ignore
    @Prop() private interfaceObject: IInterface;
    @Prop() private shiftDown!: boolean;
    @Prop() private summary!: IStatisticsSummary;
    @Prop() private cinegyRouteDestinations!: string[];
    @Prop() private cinegyRouteSources!: ICinegyRouteSource[];
    @Prop({default: ''}) private capturePath!: string;
    @Prop({default: []}) private recordings!: IRecording[];
    @Prop({default: []}) private settings!: ISetting[];
    @Prop({default: []}) private licences!: ILicences;
    @Prop({default: []}) private tags!: ITag[];

    private DefaultListenPortKey: string = 'streaming.listner.default.port';
    private ListViewDetailsKey: string = 'ui.interfaces.details.mode';

    private statistics: any = {
                labels: [],
                datasets: [
                    {
                        label: 'Bitrate Mbit/s',
                        backgroundColor: 'rgba(220,220,220,0.2)',
                        borderColor: 'rgba(220,220,220,1)',
                        pointColor: 'rgba(220,220,220,1)',
                        pointStrokeColor: '#fff',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: 'rgba(220,220,220,1)',
                        data: [],
                    },
                    {
                        label: 'Output',
                        backgroundColor: '#5bf8bf',
                        data: [],
                    },
                    {
                        label: 'Input Drops',
                        backgroundColor: 'rgba(250,128,114,0.2)',
                        borderColor: 'rgba(250,128,114,1)',
                        pointColor: 'rgba(250,128,114,1)',
                        pointStrokeColor: '#fff',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: 'rgba(250,128,114,1)',
                        data: [],
                    },
                     {
                        label: 'Output Drops',
                        backgroundColor: 'rgba(250,128,114,0.2)',
                        borderColor: 'rgba(250,128,114,1)',
                        pointColor: 'rgba(250,128,114,1)',
                        pointStrokeColor: '#fff',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: 'rgba(250,128,114,1)',
                        data: [],
                    },
              ],
        };
        private tick: number = 0;
        private lastUpdate: string = '';
        private labels: string[] = [];
        private input: string[] = [];
        private output: string[] = [];
        private iDrops: number[] = [];
        private oDrops: number[] = [];

        private inputBw: string = '0.0';
        private outputBw: string = '0.0';
        private inputDrops: string = '0';
        private outputDrops: string = '0';
        private inputRetransmitt: string = '0';
        private outputRetransmitt: string = '0';
        private timestamp: Date = new Date();
        private allowBlockLists!: IAllowBlockList[];
        private collapsed: boolean = true;


    private mounted() {
        // @ts-ignore
        this.updateInterval = setInterval(() => {
            if (!this.updateInProgress) {
                this.updateInProgress = true;
                if (this.interfaceObject && this.interfaceObject.live && this.interfaceObject.live.statistics && this.interfaceObject.live.statistics.length > 0 &&
                    this.interfaceObject.live.statistics[this.interfaceObject.live.statistics.length - 1].timestamp
                    !== this.lastUpdate) {

                    this.input = [];
                    this.output = [];
                    this.iDrops = [];
                    this.oDrops = [];
                    this.labels = [];
                    this.interfaceObject.live.statistics.forEach((s) => {
                        if (s.timestamp) {
                            // input
                            let iRate: number = 0;
                            let iDrops: number = 0;
                            let iRet: number = 0;
                            if (s && s.input_statistics) {
                                s!.input_statistics!.forEach((is) => {
                                    if (is && is.data) {
                                        is!.data!.forEach((isd) => {
                                            iRate += isd.recv.mbitRate;
                                            iDrops += isd.recv.packetsDropped;
                                            iRet += isd.recv.packetsRetransmitted;
                                        });
                                    }
                                });
                            }
                            if (this.inputBw !== iRate.toFixed(2)) {
                                this.InputBwChanged(iRate);
                            }
                            this.inputBw = iRate.toFixed(2);
                            this.inputDrops = iDrops.toFixed(0);
                            this.inputRetransmitt = iRet.toFixed(0);

                            this.input.push(iRate.toFixed(2)); // summery of valueas in this graph
                            this.iDrops.push(iDrops);
                            // output
                            let oRate: number = 0;
                            let oDrops: number = 0;
                            let oRet: number = 0;

                            if (s && s.output_statistics) {
                                s!.output_statistics!.forEach((os) => {
                                    os!.data!.forEach((osd) => {
                                        oRate += osd.send.mbitRate;
                                        oDrops += osd.send.packetsDropped;
                                        oRet += osd.send.packetsRetransmitted;
                                    });
                                });
                            }
                            if (this.outputBw !== oRate.toFixed(2)) {
                                this.OutputBwChanged(oRate);
                            }
                            this.outputBw = oRate.toFixed(2);
                            this.output.push(oRate.toFixed(2));
                            this.outputDrops = oDrops.toFixed(0);
                            this.outputRetransmitt = oRet.toFixed(0);

                            this.oDrops.push(oDrops);

                            this.labels.push(s.timestamp || '');
                        }
                    });

                    this.statistics = {
                        labels: this.labels,
                        datasets: [
                            {
                                label: 'Bitrate In Mbit/s',
                                yAxisID: 'bitrate',
                                backgroundColor: 'rgba(220,220,220,0.2)',
                                borderColor: 'rgba(220,220,220,1)',
                                pointColor: 'rgba(220,220,220,1)',
                                pointStrokeColor: '#fff',
                                pointHighlightFill: '#fff',
                                pointHighlightStroke: 'rgba(220,220,220,1)',
                                data: this.input,
                            },
                            {
                                label: 'Bitrate Out Mbit/s',
                                yAxisID: 'bitrate',
                                backgroundColor: 'rgba(180,180,180,1)',
                                borderColor: 'rgba(180,180,180,1)',
                                pointColor: 'rgba(180,180,180,1)',
                                pointStrokeColor: '#fff',
                                pointHighlightFill: '#fff',
                                pointHighlightStroke: 'rgba(180,180,180,1)',
                                data: this.output,
                            },
                            {
                                label: 'Input Drops',
                                yAxisID: 'drops',
                                backgroundColor: 'rgba(250,128,114,0.2)',
                                borderColor: 'rgba(250,128,114,1)',
                                pointColor: 'rgba(250,128,114,1)',
                                pointStrokeColor: '#fff',
                                pointHighlightFill: '#fff',
                                pointHighlightStroke: 'rgba(250,128,114,1)',
                                data: this.iDrops,
                            },
                            {
                                label: 'Output Drops',
                                yAxisID: 'drops',
                                backgroundColor: 'rgba(250,128,114,0.2)',
                                borderColor: 'rgba(250,128,114,1)',
                                pointColor: 'rgba(250,128,114,1)',
                                pointStrokeColor: '#fff',
                                pointHighlightFill: '#fff',
                                pointHighlightStroke: 'rgba(250,128,114,1)',
                                data: this.oDrops,
                            },
                        ],
                    };
                    this.lastUpdate = this.interfaceObject.live.statistics[this.interfaceObject.live.statistics.length - 1].timestamp || '';
                }

                this.timestamp = new Date();
                this.updateInProgress = false;
            }
        }, 1500);
    }
    private destroyed() {
        this.InputCountChanged(0);
        this.OutputCountChanged(0);

        this.InputBwChanged(0);
        this.OutputBwChanged(0);

        if (this.updateInterval !== -1) {
            clearInterval(this.updateInterval);
            this.updateInterval = -1;
        }
    }
    // @ts-ignore
    @Emit('start')
    // @ts-ignore
    private StartInterface(): void {
        const doNothind = true;
    }

    // @ts-ignore
    @Emit('stop')
    // @ts-ignore
    private StopInterface(): void {
        const doNothind = true;
    }

    // @ts-ignore
    @Emit('restart')
    // @ts-ignore
    private RestartInterface(): void {
        const doNothind = true;
    }

    // @ts-ignore
    @Emit('save')
    // @ts-ignore
    private SaveInterface(inter: IInterface): void {
        const doNothind = true;
    }

    // @ts-ignore
    @Emit('output-count-changed')
    // @ts-ignore
    private OutputCountChanged(newCount: number): void {
        const doNothind = true;
    }

    // @ts-ignore
    @Emit('input-count-changed')
    // @ts-ignore
    private InputCountChanged(newCount: number): void {
        const doNothind = true;
    }

    // @ts-ignore
    @Emit('output-bandwidth-changed')
    // @ts-ignore
    private OutputBwChanged(newBw: number) {
        const doNothind = true;
    }
    // @ts-ignore
    @Emit('input-bandwidth-changed')
    // @ts-ignore
    private InputBwChanged(newBw: number) {
        const doNothind = true;
    }

    private get AllowBlockLists(): IAllowBlockList[] {
        if (this.allowBlockLists !== null) {
            return this.allowBlockLists;
        }

        return [];
    }

    private get SummaryUpdatedAgo(): string {
        if (this.statisticsSummaryUpdated && this.lastUpdate) {
            return moment(this.statisticsSummaryUpdated).fromNow();
        }

        return '';
    }

    private get Settings(): { [key: string]: string } {
        const ret: { [key: string]: string } = {};

        if (this.settings && Array.isArray(this.settings)) {
            this.settings.forEach((s) => {
                ret[s.key] = s.value;
            });
        }

        return ret;
    }

    private get DefaultListenPort(): number {
        const s = this.Settings;

        if (s.hasOwnProperty(this.DefaultListenPortKey)) {
            try {
                return parseInt(s[this.DefaultListenPortKey], 10);
            } catch (E) { /* nothing to do */}
        }

        return 0;
    }

    private get ShortSommary(): IStatisticsSummaryDetail {
        if (this.summary && Array.isArray(this.summary.short_interval) && this.summary.short_interval.length > 0) {
            return this.summary.short_interval[0];
        }

        return { interface_id: '', in_retransmitted: -1, in_dropped: -1, out_retransmitted: -1, out_dropped: -1 };
    }

    private get LongSommary(): IStatisticsSummaryDetail {
        if (this.summary && Array.isArray(this.summary.long_interval) && this.summary.long_interval.length > 0) {
            return this.summary.long_interval[0];
        }

        return { interface_id: '', in_retransmitted: -1, in_dropped: -1, out_retransmitted: -1, out_dropped: -1 };
    }

    private get ListViewDetails(): string {
        const s = this.Settings;

        if (s.hasOwnProperty(this.ListViewDetailsKey)) {
            return s[this.ListViewDetailsKey];
        }

        return '';
    }

    private get InputCount(): number {
        let ret: number = 0;
        // using this.inputBw to force re-evaluation of computed
        if (this.inputBw !== undefined && this.Active && this.interfaceObject && this.interfaceObject.live && this.interfaceObject.live.statistics) {
            try {
                ret = this!.interfaceObject!.live!.statistics[this!.interfaceObject!.live!.statistics!.length - 1]!.input_statistics!.length;
            } catch (E) {/**/}
        }

        if (ret !== this.inputCount) {
            this.inputCount = ret;

            this.InputCountChanged(ret);
        }
        return ret;
    }

    private get OutputCount(): number {
        let ret: number = 0;
        // using this.outputBw to force re-evaluation of computed
        if (this.outputBw !== undefined && this.Active && this.interfaceObject && this.interfaceObject.live && this.interfaceObject.live.statistics) {
            try {
                ret = this!.interfaceObject!.live!.statistics[this!.interfaceObject!.live!.statistics!.length - 1]!.output_statistics!.length;
            } catch (E) {/**/}
        }

        if (ret !== this.outputCount) {
            this.outputCount = ret;

            this.OutputCountChanged(ret);
        }
        return ret;
    }

    private get StateClass(): string {
        let ret: string = 'inactive';
        if (this.timestamp && this.interfaceObject && this.interfaceObject.live && this.interfaceObject.live.state === 1) {
            ret = 'active';
        }

        if (!this.collapsed) {
            ret += ' opened';
        }

        return ret;
    }

    private get Inputs(): string[] {
        const ret: string[] = [];

        if (/*this.timestamp !== undefined && */this.Active && this.interfaceObject && this.interfaceObject.live) {

            if (this.inputType === 'file') {
                const u: string = this.interfaceObject.live.input[0];
                const vars: string[] = u.substring(u.indexOf('?') + 1).split('&');
                for (const v of vars) {
                    const pair: string[] = v.split('=');
                    if (pair[0] === 'recording-name') {
                        ret.push('file://' + decodeURI(pair[1]));
                    }
                }
                if (this.interfaceObject.live.input.length > 1) {
                    const path: string[] = this.interfaceObject.live.input[1].split('/');
                    if (path.length > 0) {
                        ret.push(path[path.length - 1].replace('.ts', ''));
                    } else {
                        ret.push(this.interfaceObject.live.input[1]);
                    }
                }
            } else  if (this.inputType === 'cinegy-route'/* && this.interfaceObject.live.input[0].indexOf('getvirtual') !== -1 */) {
                const u: string = this.interfaceObject.live.input[0].replace('&amp;', '&');
                const vars: string[] = u.substring(u.indexOf('?') + 1).split('&');
                let name: string = '';
                let group: string = '';
                for (const v of vars) {
                    const pair: string[] = v.split('=');
                    if (pair[0] === 'name') {
                        name = decodeURI(pair[1]);
                    } else if (pair[0] === 'group') {
                        group = decodeURI(pair[1]).replace('%2f', '/');
                    }
                }

                if (group !== '') {
                    ret.push(`${group}/${name}`);
                } else {
                    ret.push(`${name}`);
                }
                let i: number = 0;
                this.interfaceObject.live.input.forEach((input) => {
                    if (i > 0) {
                        const lurl: any = url.parse(input, false, false);
                        const mcast = input.indexOf('@') !== -1;

                        ret.push(lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port);
                    }
                    i++;
                });
            } else {
                this.interfaceObject.live.input.forEach((input) => {
                    const lurl: any = url.parse(input, false, false);
                    const mcast = input.indexOf('@') !== -1;

                    ret.push(lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port);
                });
            }
        }

        return ret;
    }

    private get InputsHint(): string {
        let ret: string = '';

        if (/*this.timestamp !== undefined &&*/ this.Active && this.interfaceObject && this.interfaceObject.live) {
            if (this.inputType === 'file') {
                // alert(this.inputType);
                const u: string = this.interfaceObject.live.input[0];
                const vars: string[] = u.substring(u.indexOf('?') + 1).split('&');
                for (const v of vars) {
                    const pair: string[] = v.split('=');
                    if (pair[0] === 'recording-name') {
                        ret += 'file://' + decodeURI(pair[1]) + '\n';
                    }
                }
                if (this.interfaceObject.live.input.length > 1) {
                    const path: string[] = this.interfaceObject.live.input[1].split('/');
                    if (path.length > 0) {
                        ret += path[path.length - 1].replace('.ts', '') + '\n';
                    } else {
                        ret += this.interfaceObject.live.input[1] +  '\n';
                    }
                }
            } else  if (this.inputType === 'cinegy-route'/* && this.interfaceObject.live.input[0].indexOf('getvirtual') !== -1 */) {
                const u: string = this.interfaceObject.live.input[0].replace('&amp;', '&');
                const vars: string[] = u.substring(u.indexOf('?') + 1).split('&');
                let name: string = '';
                let group: string = '';

                for (const v of vars) {
                    const pair: string[] = v.split('=');
                    if (pair[0] === 'name') {
                        name = decodeURI(pair[1]);
                    } else if (pair[0] === 'group') {
                        group = decodeURI(pair[1]).replace('%2f', '/');
                    }
                }

                if (group !== '') {
                     ret += `${group}/${name}`;
                } else {
                     ret += `${name}`;
                }

                let i: number = 0;
                this.interfaceObject.live.input.forEach((input, index) => {
                    if (i > 0) {
                        const lurl: any = url.parse(input, false, false);
                        const mcast = input.indexOf('@') !== -1;

                        ret += lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port + '\n';
                    }
                    i++;
                });
            } else {
                this.interfaceObject.live.input.forEach((input, index) => {
                    const lurl: any = url.parse(input, false, false);
                    const mcast = input.indexOf('@') !== -1;

                    ret += lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port + '\n';
                });
            }
        }

        return ret;
    }

    private get OutputsHint(): string {
        let ret: string = '';

        if (/*this.timestamp !== undefined && */this.Active && this.interfaceObject && this.interfaceObject.live) {
            if (this.outputType === 'file' && Array.isArray(this.interfaceObject.live.output)) {
                const u: string = this.interfaceObject.live.output[0];
                const vars: string[] = u.substring(u.indexOf('?') + 1).split('&');
                for (const v of vars) {
                    const pair: string[] = v.split('=');
                    if (pair[0] === 'recording-name') {
                        ret += 'file://' + decodeURI(pair[1]) + '\n';
                    }
                }
            } else if (Array.isArray(this.interfaceObject.live.output)) {
                this.interfaceObject.live.output.forEach((output) => {
                    const lurl: any = url.parse(output, false, false);
                    const mcast = output.indexOf('@') !== -1;

                    ret += lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port + '\n';
                });
            } else if ((this.interfaceObject.live.output as IMultipleOutputs).multi) {
                this.interfaceObject.live.output.multi.forEach((output) => {
                    const u: string = output[0];
                    const lurl: any = url.parse(u, false, false);
                    const mcast = u.indexOf('@') !== -1;

                    ret += lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port + '\n';
                });
            }
        }

        return ret;
    }

    private get Outputs(): string[] {
        const ret: string[] = [];

        if (/*this.timestamp !== undefined && */this.Active && this.interfaceObject && this.interfaceObject.live) {
            if (this.outputType === 'file' && Array.isArray(this.interfaceObject.live.output)) {
                // alert(this.inputType);
                const u: string = this.interfaceObject.live.output[0];
                const vars: string[] = u.substring(u.indexOf('?') + 1).split('&');
                for (const v of vars) {
                    const pair: string[] = v.split('=');
                    if (pair[0] === 'recording-name') {
                        ret.push('file://' + decodeURI(pair[1]));
                    }
                }
            } else if (Array.isArray(this.interfaceObject.live.output)) {
                this.interfaceObject.live.output.forEach((output) => {
                    const lurl: any = url.parse(output, false, false);
                    const mcast = output.indexOf('@') !== -1;

                    ret.push(lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port);
                });
            } else if ((this.interfaceObject.live.output as IMultipleOutputs).multi) {
                this.interfaceObject.live.output.multi.forEach((output) => {
                    const u: string = output[0];
                    const lurl: any = url.parse(u, false, false);
                    const mcast = u.indexOf('@') !== -1;

                    ret.push(lurl.protocol + '//' + (mcast ? '@' : '') + lurl.hostname + ':' + lurl.port);
                });
            }
        }

        return ret;
    }

    private get Statistics(): any {
        if (this.Active) {
            return this.statistics;
        }

        return  {
            labels: this.labels,
            datasets: [
                {
                    label: 'Bitrate In Mbit/s',
                    yAxisID: 'bitrate',
                    backgroundColor: 'rgba(220,220,220,0.2)',
                    borderColor: 'rgba(220,220,220,1)',
                    pointColor: 'rgba(220,220,220,1)',
                    pointStrokeColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(220,220,220,1)',
                    data: [],
                },
                {
                    label: 'Bitrate Out Mbit/s',
                    yAxisID: 'bitrate',
                    backgroundColor: 'rgba(180,180,180,1)',
                    borderColor: 'rgba(180,180,180,1)',
                    pointColor: 'rgba(180,180,180,1)',
                    pointStrokeColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(180,180,180,1)',
                    data: [],
                },
                {
                    label: 'Input Drops',
                    yAxisID: 'drops',
                    backgroundColor: 'rgba(250,128,114,0.2)',
                    borderColor: 'rgba(250,128,114,1)',
                    pointColor: 'rgba(250,128,114,1)',
                    pointStrokeColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(250,128,114,1)',
                    data: [],
                },
                {
                    label: 'Output Drops',
                    yAxisID: 'drops',
                    backgroundColor: 'rgba(250,128,114,0.2)',
                    borderColor: 'rgba(250,128,114,1)',
                    pointColor: 'rgba(250,128,114,1)',
                    pointStrokeColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(250,128,114,1)',
                    data: [],
                },
            ],
        };
    }

    private get StartedAt(): string {
        if (this.outputBw !== undefined && this.Active) {
            const startedAt: string = this.interfaceObject.live.started_at.substring(0, 19).replace('T', ' ');
            return startedAt;
        }

        return '';
    }

    private get RunningTime(): string {
        if (this.outputBw !== undefined && this.Active) {
            const startedAt: string = this.interfaceObject.live.started_at.substring(0, 19) + 'Z';
            return moment(startedAt).fromNow();
        }

        return '';
    }

    private get RunningTimeDetails(): string {
        // https://stackoverflow.com/questions/49385109/get-days-hour-and-minutes-diff-moment-js
        if (this.outputBw !== undefined && this.Active) {

            const startedAt: string = this.interfaceObject.live.started_at.substring(0, 19) + 'Z';
            const duration = moment.duration(moment(new Date()).diff(moment(startedAt)));

            // Get Days
            const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
            const daysFormatted = days ? `${days}d ` : ''; // if no full days then do not display it at all

            // Get Hours
            const hours = this.zeroPad(duration.hours(), 2);
            const hoursFormatted = `${hours}:`;

            // Get Minutes
            const minutes = this.zeroPad(duration.minutes(), 2);
            const minutesFormatted = `${minutes}:`;

            // Get Minutes
            const seconds = this.zeroPad(duration.seconds(), 2);
            const secondsFormatted = `${seconds}`;

            return [daysFormatted, hoursFormatted, minutesFormatted, secondsFormatted].join('');
        }

        return '';
    }

    private get Process(): IProcess | undefined {
        if (this.serverStatus && this.serverStatus.processes) {
            for (const p of this.serverStatus.processes) {
                if (this.Id === p.id) {
                    return p;
                }
            }
        }

        return undefined;
    }

    private get ProceesStatus(): string {
        if (this.Active) {
            const process: IProcess | undefined = this.Process;
            if (process) {
                return `\nCPU : ${process.cpu}%\nMemory : ${(process.memory / 1024).toFixed(2)} MB`;
            }
        }

        return '';
    }

    private zeroPad(nr: number, places: number): string {
        return String(nr).padStart(places, '0');
    }

    private showStatisticDetails() {
        this.$bvModal.show(`stats-${this.Id}`);
    }

    private setSRTInput(): void {
        const port: number = this.DefaultListenPort + (this.interfaceObject.nr * 2) - 2;

        this.interfaceObject.input = [`srt://:${port}`];
    }

    private setUDPInput(): void {
        this.interfaceObject.input = ['udp://127.0.0.1:1234'];
    }

    private setRTMPInput(): void {
        this.interfaceObject.input = ['rtmp://127.0.0.1:1935'];
    }

    private setHLSInput(): void {
        this.interfaceObject.input = ['https://127.0.0.1'];
    }

    private setRISTInput(): void {
        const port: number = this.DefaultListenPort + (this.interfaceObject.nr * 2) - 2;

        this.interfaceObject.input = [`rist://:${port}`];
    }

    private setFileInput(): void {
        this.interfaceObject.input = ['file://?playback-id=' + this.interfaceObject.nr];
    }

    private setCinegyRouteInput(): void {
        this.interfaceObject.input = ['http://localhost:9996/getvirtual?name=destination1'];
    }

    private setRelayInput(): void {
        const port: number = this.DefaultListenPort + (this.interfaceObject.nr * 2) - 2;
        this.interfaceObject.input = [`relay://:${port}`];
        this.interfaceObject.output = [`relay://`];
    }

    private setForwarderInput(): void {
        this.interfaceObject.input = ['http://localhost:9996/getvirtual?name=destination1'];
        if (this.outputType !== 'udp') {
            this.interfaceObject.output = ['udp://127.0.0.1:1234?ttl=7'];
        }
    }

    private setSRTOutput(): void {
        const port: number = this.DefaultListenPort + (this.interfaceObject.nr * 2) - 2;

        this.interfaceObject.output = [`srt://:${port}`];
    }

    private setUDPOutput(): void {
        this.interfaceObject.output = ['udp://127.0.0.1:1234?ttl=7'];
    }

    private setRISTOutput(): void {
        const port: number = this.DefaultListenPort + (this.interfaceObject.nr * 2) - 2;

        this.interfaceObject.output = [`rist://:${port}`];
    }

    private setFileOutput(): void {
        this.interfaceObject.output = ['file://'];
    }

    private setMultiOutput(): void {
        this.interfaceObject.output = { multi : [] };
    }

    private setTUDPOutput(): void {
        this.interfaceObject.output = ['tudp://239.0.0.1:5000?tshift=5000&ttl=7'];
    }

    private setNDIOutput(): void {
        this.interfaceObject.output = ['ndi://streamName'];
    }

    private getType(iurl: string): string {
        if (iurl.substring(0, 3).toLowerCase() === 'udp' || iurl.substring(0, 3).toLowerCase() === 'rtp') {
            return 'udp';
        } else if (iurl.substring(0, 3).toLowerCase() === 'srt') {
            return 'srt';
        } else if (iurl.substring(0, 4).toLowerCase() === 'rtmp') {
            return 'rtmp';
        } else if (iurl.substring(0, 4).toLowerCase() === 'rist') {
            return 'rist';
        } else if (iurl.substring(0, 4).toLowerCase() === 'http' && iurl.indexOf('getvirtual') !== -1) {
            return 'cinegy-route';
        } else if (iurl.substring(0, 4).toLowerCase() === 'file') {
            return 'file';
        } else if (iurl.substring(0, 5).toLowerCase() === 'https' || iurl.substring(0, 4).toLowerCase() === 'http') {
            return 'hls';
        } else if (iurl.substring(0, 5).toLowerCase() === 'relay') {
            return 'relay';
        } else if (iurl.substring(0, 4).toLowerCase() === 'tudp') {
            return 'tudp';
        } else if (iurl.substring(0, 4).toLowerCase() === 'ndi') {
            return 'ndi';
        }
        return iurl.substring(0, 3);
    }

    private get inputType(): string {
        if (this.interfaceObject && this.interfaceObject.input && this.interfaceObject.input.length > 0) {
            /*if (this.interfaceObject.input[0].substring(0, 3).toLowerCase() === 'udp') {
                return 'udp';
            } else if (this.interfaceObject.input[0].substring(0, 3).toLowerCase() === 'srt') {
                return 'srt';
            } else if (this.interfaceObject.input[0].substring(0, 4).toLowerCase() === 'rtmp') {
                return 'rtmp';
            } else if (this.interfaceObject.input[0].substring(0, 4).toLowerCase() === 'rist') {
                return 'rist';
            } else if (this.interfaceObject.input[0].substring(0, 4).toLowerCase() === 'http' && this.interfaceObject.input[0].indexOf('getvirtual') !== -1) {
                return 'cinegy-route';
            } else if (this.interfaceObject.input[0].substring(0, 4).toLowerCase() === 'file') {
                return 'file';
            } else if (this.interfaceObject.input[0].substring(0, 7).toLowerCase() === 'http://' || this.interfaceObject.input[0].substring(0, 5).toLowerCase() === 'https') {
                return 'hls';
            }
            return this.interfaceObject.input[0].substring(0, 3);*/
            return this.getType(this.interfaceObject.input[0]);
        }
        return '';
    }

    private get outputType(): string {
        if (this.interfaceObject && this.interfaceObject.output && Array.isArray(this.interfaceObject.output) && this.interfaceObject.output.length > 0) {
           /* if (this.interfaceObject.output[0].substring(0, 3).toLowerCase() === 'udp') {
                return 'udp';
            } else if (this.interfaceObject.output[0].substring(0, 3).toLowerCase() === 'srt') {
                return 'srt';
            } else if (this.interfaceObject.output[0].substring(0, 4).toLowerCase() === 'rtmp') {
                return 'rtmp';
            } else if (this.interfaceObject.output[0].substring(0, 4).toLowerCase() === 'rist') {
                return 'rist';
            } else if (this.interfaceObject.output[0].substring(0, 4).toLowerCase() === 'file') {
                return 'file';
            }*/
            return this.getType(this.interfaceObject.output[0]);
        } else if (this.interfaceObject && this.interfaceObject.output && ((this.interfaceObject.output as IMultipleOutputs)).multi) {
            return 'multi';
        }
        return '';
    }

    private startInterface(): void {
        this.$bvModal.msgBoxConfirm(`Start ${this.Name}`, {
            title: 'Start Interface',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        })
        .then((value) => {
            if (value) {
                this.StartInterface();
            }
        })
        .catch((err) => {
            // An error occurred
        });
    }

    private restartInterface(): void {
        this.$bvModal.msgBoxConfirm(`Restart ${this.Name}`, {
            title: 'Restart Interface',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        })
        .then((value) => {
            if (value) {
                this.RestartInterface();
            }
        })
        .catch((err) => {
            // An error occurred
        });
    }

    private stopInterface(): void {
        this.$bvModal.msgBoxConfirm(`Stop ${this.Name}`, {
            title: 'Stop Interface',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        })
        .then((value) => {
            if (value) {
                this.StopInterface();
            }
        })
        .catch((err) => {
            // An error occurred
        });
    }

    private saveInterface(): void {
        this.SaveInterface(this.interfaceObject);
    }

    private nameChanged(newName: string) {
        this.interfaceObject.name = newName;
    }

    private autostartChanged(newValue: boolean) {
        this.interfaceObject.auto_start = newValue;
    }

    private srtConnectionLimitChanged(newValue: number) {
        this.interfaceObject.srt_connection_limit = newValue;
    }

    private allowBlockListChanged(newValue: string) {
        this.interfaceObject.allow_block_list_id = newValue;
    }

    private inputChanged(newConfig: string[]) {
        if (newConfig && newConfig.length === 0 && this.interfaceObject.input.length > 0 && this.interfaceObject.input[0].indexOf('relay://') !== -1) {
            this.interfaceObject.output = [];
        }

        this.interfaceObject.input = newConfig;
    }
    private outputChanged(newConfig: string[] | IMultipleOutputs) {
       this.interfaceObject.output = newConfig;
    }

    private get GraphStyles(): any {
        return {
            height: '80px',
        };
    }

    private get Id(): string {
        if (this.interfaceObject) {
            return this.interfaceObject.id;
        }

        return '';
    }

    private get Nr(): number {
        if (this.interfaceObject) {
            return this.interfaceObject.nr;
        }

        return 0;
    }

    private get Type(): number {
        if (this.interfaceObject) {
            return this.interfaceObject.type;
        }

        return 0;
    }

    private get Name(): string {
        if (this.interfaceObject.live) {
            return this.interfaceObject.live.name;
        }

        return this.interfaceObject.name;
    }

    private get SrtConnectionLimit(): number {
        return this.interfaceObject.srt_connection_limit;
    }
    private get AllowBlockList(): string {
        return this.interfaceObject.allow_block_list_id;
    }

    private get Autostart(): boolean {
        if (this.interfaceObject) {
            return this.interfaceObject.auto_start;
        }

        return false;
    }

    private get Bitrate(): string {
        if (this.interfaceObject.live !== null) {
            return '0'; // this.interfaceObject.Status.MbitRate.toFixed(2);
        }

        return '';
    }

    private get InputAddress(): string {
        if (this.interfaceObject.live !== null) {
            return ''; // this.interfaceObject.live.InputAddress;
        }

        return ''; // this.interfaceObject.InputAddress;;
    }

    private get InputPort(): number {
        if (this.interfaceObject.live !== null) {
            return 0; // this.interfaceObject.live.InputPort;
        }

        return 0; // this.interfaceObject.InputPort;;
    }

    private get OutputAddress(): string {
        if (this.interfaceObject.live !== null) {
            return ''; // this.interfaceObject.live.OutputAddress;
        }

        return ''; // this.interfaceObject.OutputAddress;;
    }

    private get OutputPort(): number {
        if (this.interfaceObject.live !== null) {
            return 0; // this.interfaceObject.live.OutputPort;
        }

        return 0; // this.interfaceObject.OutputPort;;
    }

    private get State(): string {
        if (this.interfaceObject.live !== null) {
            return ''; // this.interfaceObject.Status.State;
        }

        return '';
    }

    private get Active(): boolean {
        if (this.timestamp && this.interfaceObject && this.interfaceObject.live && this.interfaceObject.live.state === 1) {
            return true;
        }
        return false;
    }

    private get IsDataUpToDate(): boolean {
        const limtActive = moment().subtract(5, 'seconds');

        if (this.interfaceObject.lastTimestamp === undefined || this.interfaceObject.lastTimestamp === null) {
            return true;
        }

        // console.log('tag', moment(this.interfaceObject.lastTimestamp + "Z") + " Is after " + limtActive + " " + moment(this.timestamp + "Z").isAfter(limtActive));

        return moment(this.interfaceObject.lastTimestamp + 'Z').isAfter(limtActive);
    }

    private get InputBw(): string {
        if (this.Active /*&& this.IsDataUpToDate*/) {
            return this.inputBw;
        }

        return '0.0';
    }

    private get OutputBw(): string {
        if (this.Active /*&& this.IsDataUpToDate*/) {
            return this.outputBw;
        }

        return '0.0';
    }

    private get InputDrops(): string {
        if (this.Active /*&& this.IsDataUpToDate*/) {
            return this.inputDrops;
        }

        return '0';
    }

    private get OutputDrops(): string {
        if (this.Active /*&& this.IsDataUpToDate*/) {
            return this.outputDrops;
        }

        return '0';
    }

    private get InputRetransmitt(): string {
        if (this.Active /*&& this.IsDataUpToDate*/) {
            return this.inputRetransmitt;
        }

        return '0';
    }

    private get OutputRetransmitt(): string {
        if (this.Active /*&& this.IsDataUpToDate*/) {
            return this.outputRetransmitt;
        }

        return '0';
    }

    private toggle(id: string) {
        this.$root.$emit('bv::toggle::collapse', `edit-collapse-${id}`);

        this.collapsed = !this.collapsed;
    }

    private inputTypeChange(newUrl: string) {
        if (this.getType(newUrl) !== this.getType(this.interfaceObject.input[0])) {
            this.$bvModal.msgBoxConfirm(`This will change input from ${this.getType(this.interfaceObject.input[0])} to ${this.getType(newUrl)}`, {
                title: 'Input type change',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            })
            .then((value) => {
                if (value) {
                    this.interfaceObject.input = [newUrl];
                }
            })
            .catch((err) => {
                // An error occurred
            });
        } else {
            this.interfaceObject.input = [];
            setTimeout(() => {
                this.interfaceObject.input = [newUrl];
            }, 10);
        }
    }

    private outputTypeChange(newUrl: string) {
        if (Array.isArray(this.interfaceObject.output) && this.getType(newUrl) !== this.getType(this.interfaceObject.output[0])) {
            this.$bvModal.msgBoxConfirm(`This will change output from ${this.getType(this.interfaceObject.output[0])} to ${this.getType(newUrl)}`, {
                title: 'Output type change',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            })
            .then((value) => {
                if (value) {
                    this.interfaceObject.output = [newUrl];
                }
            })
            .catch((err) => {
                // An error occurred
            });
        } else {
            this.interfaceObject.output = [];
            setTimeout(() => {
                this.interfaceObject.output = [newUrl];
            }, 10);
        }
    }

    private setInputURL(newURL: string) {
        this.interfaceObject.input = [newURL];
    }

    private setOutputURL(newURL: string) {
        this.interfaceObject.output = [newURL];
    }
}
