/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://xjy3s3nggveolihtjh6o6hofeu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-olhrbsprcvhw3fcr7tbj23u2tm",
    "aws_cognito_identity_pool_id": "eu-west-1:c712746f-650b-4b80-9316-22da5eb8a2fe",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_DZEotqEXJ",
    "aws_user_pools_web_client_id": "2ta8n9v5u0ed564o7c90on65ed",
    "oauth": {}
};


export default awsmobile;
